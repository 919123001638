export type GroupRegistrationFieldsV1 = {
  groupName: string
  adGroup: string
  ip: string
  port: string
}

export type GroupRegistrationFieldsV2 = {
  groupName: string
  groupType: GroupType
  adGroup: string
  ip: string
  port: string
}

export type GroupRegistrationFields = {
  groupName: string
  groupType: GroupType | null
  adGroup: string
  ip: string
  port: string
}

export enum GroupType {
  EDGE = 'edge',
  CLOUD = 'cloud'
}

export type V1GroupRegistrationResult = GroupRegistrationFieldsV1 | null | Error

export type V2GroupRegistrationResult = GroupRegistrationFieldsV2 | null | Error

export type Group = {
  adGroupId: string
  groupType: GroupType
  ip: string
  port: string
  groupName: string
  deploymentStatus: string
  groupStatus: string
}

export const groupTypeDropdownValues: {
  value: GroupType
  label: string
}[] = [
  { value: GroupType.EDGE, label: 'Edge' },
  { value: GroupType.CLOUD, label: 'Cloud' }
]

