/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { Component, ChangeEvent, SyntheticEvent } from 'react'
import { NextGen } from '@nike/epic-react-ui'
import { registrationSectionTitle } from '../commonStyles'
import RestrictedTextInput from '../components/RestrictedTextInput'
import {
  validateAdGroupName,
  validateName,
  validateLongitude,
  validateLatitude,
  latErrorMessage,
  longErrorMessage,
  nameValidationMessage
} from '../registration/utils'
import { createRegisterSupplierPayload, registerSupplier } from '../util/apiUtil'
import ErrorMessage from '../components/ErrorMessage'
import { SupplierType, supplierTypeDropdownValues } from './types'
import Spacer from '../components/Spacer'
import { RegisterButton } from '../registration/RegisterButton'
import { validateSupplierRegistrationPayload } from './utils'

type SupplierRegistrationFormProps = {
  onRegistrationComplete: (supplierName: string, error: string) => void
}

type SupplierRegistrationFormState = {
  supplierType: SupplierType
  supplierCode: string
  supplierName: string
  latitude: string
  longitude: string
  [x: string]: any
}

class SupplierRegistrationForm extends Component<
  SupplierRegistrationFormProps,
  SupplierRegistrationFormState
> {
  constructor(props: SupplierRegistrationFormProps) {
    super(props)
    this.state = {
      supplierType: SupplierType.DC,
      supplierCode: '',
      supplierName: '',
      latitude: '',
      longitude: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  handleSelectSupplierType = (selected: SupplierType) => {
    let type = selected
    if (selected.hasOwnProperty('value')) {
      type = selected['value']
    }
    this.setState({
      supplierType: type
    })
  }

  async handleSubmit(event: SyntheticEvent) {
    const {
      supplierType,
      supplierCode,
      supplierName,
      latitude,
      longitude
    } = this.state

    const jsonPayload = createRegisterSupplierPayload(
      supplierType,
      supplierCode,
      supplierName,
      latitude,
      longitude
    )

    const errorMessage = validateSupplierRegistrationPayload(jsonPayload)

    if (errorMessage) {
      this.setState({ errorMessage })
      return
    }
    let registrationError = ''
    try {
      await registerSupplier(jsonPayload)
    } catch (err) {
      console.log('ERROR', err)
      registrationError = err instanceof Error ? err.message : 'ERROR: Unknown Error.'
    }

    this.props.onRegistrationComplete(supplierName, registrationError)
    event.persist()
  }

  render() {
    const {
      supplierCode,
      supplierType,
      supplierName,
      latitude,
      longitude,
      errorMessage
    } = this.state

    return (
      <div
        style={{ display: 'table', width: '100%', paddingTop: '15px' }}
        aria-label={'Register Supplier Form'}
      >
        <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
          <div className='epic-font-brand' css={registrationSectionTitle}>
            General Information
          </div>
          <RestrictedTextInput
            required
            onChange={this.handleChange}
            name='supplierName'
            label='Supplier Name'
            value={supplierName}
            validation={validateName}
            errorMessage={nameValidationMessage}
          />
          <NextGen.Select
            required
            helperText='Supplier Type'
            aria-label='Supplier Type'
            options={supplierTypeDropdownValues}
            value={supplierTypeDropdownValues.find(item => item.value === supplierType)}
            onChange={this.handleSelectSupplierType}
          />
          <Spacer height={15} />
          <RestrictedTextInput
            required
            onChange={this.handleChange}
            name='supplierCode'
            label='Supplier Code'
            value={supplierCode}
            validation={validateAdGroupName}
            errorMessage={'Only 0-9, a-z, A-Z, ., _, and - permitted'}
          />
        </div>
        <div style={{ display: 'table-cell' }}>
          <div className='epic-font-brand' css={registrationSectionTitle}>
            Location
          </div>
          <RestrictedTextInput
            required
            onChange={this.handleChange}
            name='latitude'
            label='Latitude'
            value={latitude}
            validation={validateLatitude}
            errorMessage={latErrorMessage}
            type={'number'}
          />
          <RestrictedTextInput
            required
            onChange={this.handleChange}
            name='longitude'
            label='Longitude'
            value={longitude}
            validation={validateLongitude}
            errorMessage={longErrorMessage}
            type={'number'}
          />
          <RegisterButton type='Supplier' handleSubmit={this.handleSubmit} />

          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
      </div>
    )
  }
}

export default SupplierRegistrationForm
