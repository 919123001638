import { Map, fromJS } from 'immutable'

import { storeVersion, SET_VERSION } from './actions'

// Default the region to the last selected region (which survives browser restarts)
// If the user has never selected a region before, default to us-west-2
const defaultVersion = localStorage.getItem('lastSelectedVersion') || 'v1'

export default function multiversion(state = defaultVersion, action) {
  switch (action.type) {
    case SET_VERSION.type:
      return action.value
    default:
      return state
  }
}
export const versionSelector = (state): string => state.multiversion
