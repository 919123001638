import { createAction } from '@nike/redux-action-utils'

export const SET_REGION = createAction('SET_REGION')

export function storeRegion(region) {
  return {
    type: SET_REGION.type,
    value: region
  }
}
