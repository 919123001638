/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText } from '../commonStyles'

type DeploymentFailureProps = {
  resetDeployment: () => void
  error: string
}

const DeploymentFailure = (props: DeploymentFailureProps) => {
  const { error } = props
  return (
    <div>
      <div className='epic-font-brand' css={largeText}>
        Core device deployment failure.
      </div>
    </div>
  )
}

export default DeploymentFailure
