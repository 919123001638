/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, Spinner } from '@nike/epic-react-ui'
import { deleteDevice, getDevice } from '../util/apiUtil'
import { DeviceType } from '../registration/types'
import { themedButton } from '../commonStyles'
import ErrorMessage from '../components/ErrorMessage'

type DeleteModalProps = {
  deviceName: string
  onClose: () => void
}

type DeleteModalState = {
  groupName?: string
  isLoading: boolean
  errorMessage: string
}

class DeleteModal extends Component<DeleteModalProps, DeleteModalState> {
  constructor(props: DeleteModalProps) {
    super(props)
    this.state = {
      groupName: '',
      isLoading: false,
      errorMessage: ''
    }
    this.handleDelete = this.handleDelete.bind(this)
  }

  async componentDidUpdate(prevProps: DeleteModalProps) {
    if (prevProps.deviceName === this.props.deviceName || !this.props.deviceName) {
      return
    }
    const res = await getDevice(this.props.deviceName)
    const deviceType: DeviceType = res.deviceType ? DeviceType[res.deviceType.toUpperCase()] : ''
    this.setState({
      groupName: res.groupName
    })
  }

  async handleDelete() {
    this.setState({ isLoading: true })
    
    let response: any = await deleteDevice(this.props.deviceName)

    if (response.status !== 200 && response.status !== 201) {
      this.setState({ isLoading: false })
    }

    if (response.status === 409) {
      this.setState({ errorMessage: 'Device is in an invalid state. Try again in a few minutes.' })
      return
    }

    this.props.onClose()
  }

  render() {
    const { onClose, deviceName } = this.props
    return (
      <div>
        <Modal modalSize='sm' onClose={onClose} closeButton show={!!deviceName}>
          <div aria-label='Delete Modal'>
            <Modal.Title>
              <h1>Delete</h1>
            </Modal.Title>
            <Modal.Content>
              <div css={deleteTextBox}>
                Are you sure you would like to delete this device ({deviceName})?
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={this.state.isLoading} css={ this.state.isLoading ? '' : deleteButton} onClick={this.handleDelete}>
                { this.state.isLoading ? <Spinner /> : 'Delete' }
              </Button>
              <Button onClick={onClose} theme={themedButton} inverse aria-label='Close Delete'>
                Cancel
              </Button>
              {this.state.errorMessage && <ErrorMessage errorMessage={this.state.errorMessage} />}
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    )
  }
}

export default DeleteModal

const deleteButton = css`
  background-color: #fe0000;
`
const deleteTextBox = css`
  text-align: center;
`
