import useInterval from '../util/useInterval'

type UseIntervalProps = {
  callback: () => any
  delay: number
}

const UseInterval = (props: UseIntervalProps) => {
  const { callback, delay } = props
  useInterval(async () => {
    await callback()
  }, delay)

  return <div />
}

export default UseInterval
