import merge from 'deepmerge'

const isDev = () =>
  window.location.origin.includes('dev.sensors-test.nikecloud') ||
  window.location.origin.includes('localhost:')
const isPreProd = () => window.location.origin.includes('preprod.sensors-test.nikecloud')

const getRegions = () => {
  if (isDev) {
    return [
      { value: 'us-west-2', label: 'US West (Oregon)' },
      { value: 'eu-west-1', label: 'EU (Ireland)' },
      { value: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' }
    ]
  } else if (isPreProd) {
    return [
      { value: 'us-west-2', label: 'US West (Oregon)' },
      { value: 'eu-west-1', label: 'EU (Ireland)' },
      { value: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' }
    ]
  } else {
    return [
      { value: 'us-west-2', label: 'US West (Oregon)' },
      { value: 'eu-west-1', label: 'EU (Ireland)' },
      { value: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' }
    ]
  }
}

const getVersions = () => {
    return [
      { value: 'v1', label: 'V1' },
      { value: 'v2', label: 'V2' }
    ]
}

const base = {
  defaultRegion: 'us-west-2',
  regions: getRegions(),
  defaultVersion: 'v1',
  versions: getVersions(),
  appName: 'cp-device-registration-ui',
  appFullName: 'IDEdge Device Management',
  oAuth: {
    scope: 'profile openid email connectedid.iotdevicemanager.admin.create',
    client_id: 'nike.niketech.cp-iot-device-management',
    response_type: 'authorization_code',
    redirect_uri: window.location.origin + '/'
  }
}

const dev = merge(base, {
  apiUrl: '-device-manager-dev.sensors-test.nikecloud.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
    token: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token'
  },
  workspace: 'dev'
})

const preprod = merge(base, {
  apiUrl: '-device-manager-preprod.sensors-test.nikecloud.com',
  oAuth: {
    authorization: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize',
    token: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/token'
  },
  workspace: 'preprod'
})

const prod = merge(base, {
  apiUrl: '-device-manager.sensors.nikecloud.com',
  oAuth: {
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
    token: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token'
  },
  workspace: 'prod'
})

let config
if (isPreProd()) {
  config = preprod
} else if (isDev()) {
  config = dev
} else {
  config = prod
}

export default config
