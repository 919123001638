/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, mediumText, smallMargin } from '../commonStyles'
import { GroupRegistrationFields, GroupRegistrationFieldsV1, GroupRegistrationFieldsV2 } from './types'

type RegistrationFailureProps = {
  resetRegistration: () => void
  error: Error | null | GroupRegistrationFieldsV1 | GroupRegistrationFieldsV2
}

const RegistrationFailure = (props: RegistrationFailureProps) => {
  const { error } = props
  const errorMessage = error instanceof Error ? error.message : 'null registration result'
  return (
    <div>
      <div className='epic-font-brand' css={largeText}>
        Device registration failure :(
      </div>
      <div className='epic-font-brand' css={mediumText}>
        Failed to register group. Error: {errorMessage}
      </div>
      <IconButton type='arrow-backward' positionFirst onClick={() => navigate('/home')}>
        Device List
      </IconButton>
      <Button onClick={props.resetRegistration} css={smallMargin}>
        Register New Group
      </Button>
    </div>
  )
}

export default RegistrationFailure
