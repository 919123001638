import { Map, fromJS } from 'immutable'
import { READ_ONLY_GROUP } from '../root/constants'

import { SET_AD_GROUPS } from './events'

type adGroupsState = string[]
const defaultState = []

export default function adGroups(state: adGroupsState = defaultState, action) {
  switch (action.type) {
    case SET_AD_GROUPS:
      return action.payload
    default:
      return state
  }
}

export const adGroupsSelector = (state): string[] => state.adGroups
export const isReadOnlySelector = (state): boolean => state.adGroups.includes(READ_ONLY_GROUP)
