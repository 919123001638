import { DeviceInListDevices } from '../registration/types'
import { setDevices } from './actions'

type devicesState = any[]
const defaultState = []

export default function devices(state: devicesState = defaultState, action) {
  switch (action.type) {
    case setDevices.type:
      return action.payload
    default:
      return state
  }
}

export const devicesSelector = (state): DeviceInListDevices[] => state.devices
