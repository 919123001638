export const groupNavBarLinks = {
  DEFAULT: { links: [{ text: 'Home', path: '/home' }] },
  'App.cp-idedge.global.admin': {
    links: [
      { text: 'Home', path: '/home' },
      { text: 'Register Device', path: '/device-registration' },
      { text: 'Register Group', path: '/group-registration' },
      { text: 'Register Supplier', path: '/suppliers' },
      { text: 'Admin', path: '/admin' }
    ]
  },
  'App.cp-idedge.wings.admin': {
    links: [
      { text: 'Home', path: '/home' },
      { text: 'Register Device', path: '/device-registration' }
    ]
  },
  'App.cp-idedge.wings.read': { links: [{ text: 'Home', path: '/home' }] }
}

export const READ_ONLY_GROUP = 'App.cp-idedge.wings.read'
