import semverRegex from 'semver-regex'
import { InnerJSON, JSONPayload, SubscriptionType } from './types'

export function validateLatitude(value: string): boolean {
  if (!value) {
    return false
  }
  let v = parseFloat(value)
  return v >= -90 && v <= 90 && validateDecimalPrecision(value)
}

export function validateLongitude(value: string): boolean {
  if (!value) {
    return false
  }
  let v = parseFloat(value)
  return v >= -180 && v <= 180 && validateDecimalPrecision(value)
}

function validateDecimalPrecision(value: string, precision = 5): boolean {
  if (!value) {
    return false
  }
  try {
    const digitsAfterDecimal = value.split('.')[1]?.length || 0
    const number = Number(value)
    if (isNaN(number) || digitsAfterDecimal < precision) {
      return false
    }
  } catch {
    return false
  }
  return true
}

enum TopicSubscriptions {
  MAX = 10
}

function validateSubscriptionTopics(topics: Array<string>) {
  if (topics.length > TopicSubscriptions.MAX) {
    return false
  }

  return true
}

const iotAttributeRegex = new RegExp('^[a-zA-Z0-9_.,@/:#-]*$')
const deviceNameRegex = new RegExp('^[a-zA-Z0-9_-]+$')
const adGroupRegex = new RegExp('^[a-zA-Z0-9._-]+$')
const ipRegex = new RegExp(
  '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
)

export function validateName(value: string) {
  // We are are capped at 800 chars by AWS when we put these attribute values into the IoT Thing AttributePayload.
  return value.match(deviceNameRegex) && value.length <= 800
}

export const nameValidationMessage =
  'Value must be 800 characters or less and contain only: a-z A-Z 0-9 _ -'

export function validateIoTAttributeValue(value: string) {
  // We are are capped at 800 chars by AWS when we put these attribute values into the IoT Thing AttributePayload.
  return value.match(iotAttributeRegex) && value.length <= 800
}

export const iotAttributeValidationMessage =
  'Value must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'

export function validateAdGroupName(value: string) {
  return value.match(adGroupRegex)
}

export function validateIP(value: string) {
  return value.match(ipRegex)
}

export function validatePort(value: string) {
  let x = parseInt(value, 10)
  return x >= 0 && x <= 65535
}

export const latErrorMessage =
  'Latitude must be between -90 and 90 and have 5 decimals of precision'
export const longErrorMessage =
  'Longitude must be between -180 and 180 and have 5 decimals of precision'

export function validateSemver(value: string) {
  return semverRegex().test(value)
}

export function validateJSONPayload(jsonPayload: JSONPayload) {
  let errorMessage = ''

  const { deviceName, deviceType, deviceTags } = jsonPayload

  const {
    serialNumber,
    manufacturer,
    model,
    firmwareName,
    firmwareVersion,
    readpointValue,
    locationLongitude,
    locationLatitude,
  } = deviceTags

  if (!deviceName?.length || !deviceType?.length) {
    errorMessage = 'Basic device information fields may not be left empty'
  } else if (findEmptyDeviceFields(deviceTags).length) {
    errorMessage = `All required Tag Device Fields must be non-empty: ${findEmptyDeviceFields(
      deviceTags
    )}`
  } else if (!validateIoTAttributeValue(serialNumber)) {
    errorMessage =
      'Serial number must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'
  } else if (!validateIoTAttributeValue(manufacturer)) {
    errorMessage =
      'Manufacturer must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'
  } else if (!validateIoTAttributeValue(model)) {
    errorMessage =
      'Model must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'
  } else if (!validateIoTAttributeValue(firmwareName)) {
    errorMessage =
      'Firmware name must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'
  } else if (!validateIoTAttributeValue(readpointValue)) {
    errorMessage =
      'Readpoint value must be 800 characters or less and contain only: a-z A-Z 0-9 _ . , @ / : # -'
  } else if (!validateSemver(firmwareVersion) || !validateIoTAttributeValue(firmwareVersion)) {
    errorMessage = 'Firmware version must be valid semver i.e. v1.1.1'
  } else if (!validateLongitude(locationLongitude)) {
    errorMessage =
      'Invalid Longitude. Value must be between -180 and 180 and have 5 decimals of precision'
  } else if (!validateLatitude(locationLatitude)) {
    errorMessage =
      'Invalid Latitude. Value must be between -90 and 90 and have 5 decimals of precision'
  }

  return errorMessage
}

function findEmptyDeviceFields(d: InnerJSON): string[] {
  const isLocal = d.subscriptionType === SubscriptionType.VM_TO_LOCAL_DEVICE
  const emptyFields = Object.keys(d).filter(key => {
    // Local device subs can be empty is cloud device
    if (key === 'localDeviceSubscriptions' && !isLocal) {
      return false
    }
    // Cloud device subs can be empty if local device
    if (
      [
        'publisherAssociationTopic',
        'publisherMetricTopic',
        'subscriberAssociationTopic',
        'subscriberMetricTopic'
      ].includes(key) &&
      isLocal
    ) {
      return false
    }
    return !d[key]?.length
  })
  return emptyFields
}
