/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { getDevice, listGroups } from '../util/apiUtil'
import { Modal, Button, StaticTable } from '@nike/epic-react-ui'
import { String } from 'aws-sdk/clients/apigateway'
import { themedButton } from '../commonStyles'

type ConnectivityModalProps = {
  deviceName: string
  onClose: () => void
}

interface Rows {
  title: string
  value: number | string | undefined
}

export default function ConnectivityModal(props: ConnectivityModalProps) {
  const [clientId, setClientId] = useState('')
  const [associationTopicOutbound, setAssociationTopicOutbound] = useState<string | undefined>('')
  const [metricTopicOutbound, setMetricTopicOutbound] = useState<string | undefined>('')
  const [associationTopicInbound, setAssociationTopicInbound] = useState<string | undefined>('')
  const [metricTopicInbound, setMetricTopicInbound] = useState<string | undefined>('')
  const [deviceId, setDeviceId] = useState('')
  const [deviceType, setDeviceType] = useState('')
  const [vmIp, setVmIp] = useState('')
  const [vmPort, setVmPort] = useState<number | undefined>(undefined)
  const [localDeviceSubscription, setLocalDeviceSubscription] = useState<string[] | undefined>([])

  useEffect(() => {
    getModalDetails()
  }, [])

  useEffect(() => {
    getModalDetails()
  }, [props.deviceName])

  async function getModalDetails() {
    if (!props.deviceName) {
      return
    }
    const deviceRes = await getDevice(props.deviceName)
    const groupRes = await listGroups()
    const listOfGroups = groupRes.groups
    const fullGroupDetails = listOfGroups.find(group => group.groupName == deviceRes?.groupName)
    if (fullGroupDetails != undefined) {
      setVmIp(fullGroupDetails?.ip)
      setVmPort(fullGroupDetails?.port)
    }
    setClientId(deviceRes?.deviceName)
    setAssociationTopicOutbound(deviceRes?.subscriberAssociationTopic)
    setMetricTopicOutbound(deviceRes?.subscriberMetricTopic)
    setAssociationTopicInbound(deviceRes?.publisherAssociationTopic)
    setMetricTopicInbound(deviceRes?.publisherMetricTopic)
    setLocalDeviceSubscription(deviceRes?.localDeviceSubscriptions)
    setDeviceId(deviceRes?.deviceId)
    setDeviceType(deviceRes?.subscriptionType)
    setDeviceId(deviceRes?.deviceId)
  }

  const { deviceName, onClose } = props
  const rows: Rows[] = [
    {
      title: 'MQTT ClientId',
      value: clientId
    },
    {
      title: 'Device Type',
      value: deviceType
    },
    {
      title: 'VM IP Address',
      value: vmIp
    },
    {
      title: 'VM Port',
      value: vmPort
    }
  ]

  rows.push({
    title: 'Publish Association Topic',
    value: associationTopicInbound
  })
  rows.push({
    title: 'Publish Metric Topic',
    value: metricTopicInbound
  })

  if (deviceType === 'Local') {
    localDeviceSubscription?.forEach(subscription => {
      rows.push({
        title: `${subscription.split('/')[5]} Topic`,
        value: subscription
      })
    })
  }
  return (
    <div>
      <Modal modalSize='md' onClose={onClose} closeButton show={!!deviceName}>
        <div aria-label='Connectivity Modal'>
          <Modal.Title>
            <h1>Device Connectivity</h1>
            <h1>{deviceName}</h1>
          </Modal.Title>
          <Modal.Content>
            <div>
              <StaticTable
                columns={[
                  {
                    accessor: 'title'
                  },
                  {
                    accessor: 'value'
                  }
                ]}
                data={rows}
                isCompact
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button theme={themedButton} inverse onClick={onClose} aria-label='Close Connectivity'>
              Close
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    </div>
  )
}
