/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, Spinner } from '@nike/epic-react-ui'
import { createDeleteGroupPayload, deleteGroup } from '../util/apiUtil'
import { Group } from './types'
import ErrorMessage from '../components/ErrorMessage'

type DeleteModalProps = {
  group: Group | null
  onClose: () => void
}

type DeleteModalState = {
  isLoading: boolean
  errorMessage: string
}

export default class DeleteGroupModal extends Component<DeleteModalProps, DeleteModalState> {
  constructor(props: DeleteModalProps) {
    super(props)
    this.state = {
      isLoading: false,
      errorMessage: ''
    }
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete = async () => {
    this.setState({ isLoading: true })

    const groupName = this.props.group?.groupName || null
    if (!groupName) {
      return
    }

    const deletePayload = createDeleteGroupPayload(groupName)
    let response: any = await deleteGroup(deletePayload)

    if (response.status !== 200 && response.status !== 201) {
      this.setState({ isLoading: false })
    }

    if (response.status === 409) {
      this.setState({ errorMessage: 'Device is in an invalid state. Try again in a few minutes.' })
      return
    }


    this.handleClose()
  }

  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const showModal = !!this.props.group
    return (
      <div>
        <Modal modalSize='sm' onClose={this.handleClose} closeButton show={showModal}>
          <div aria-label='delete group modal'>
            <Modal.Title>
              <h1>Delete {this.props.group?.groupName}</h1>
            </Modal.Title>
            <Modal.Content>
              <div css={deleteTextBox}>
                Are you sure you would like to delete this group ({this.props.group?.groupName} for{' '}
                {this.props.group?.adGroupId})?
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button disabled={this.state.isLoading} aria-label='delete group' css={ this.state.isLoading ? '' : deleteButton} onClick={this.handleDelete}>
                { this.state.isLoading ? <Spinner /> : 'Delete' }
              </Button>
              <Button aria-label='close delete modal' onClick={this.handleClose}>
                Cancel
              </Button>
              {this.state.errorMessage && <ErrorMessage errorMessage={this.state.errorMessage} />}
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    )
  }
}

const deleteButton = css`
  background-color: #fe0000;
`
const deleteTextBox = css`
  text-align: center;
`
