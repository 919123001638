/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Supplier } from '../util/apiUtil';
import UseInterval from '../components/UseInterval';
import DeleteModal from './DeleteModal';
import SupplierRegistrationForm from './SupplierRegistrationForm';
import SuppliersList from './SuppliersList';
import RegistrationSuccess from './RegistrationSuccess';
import RegistrationFailure from './RegistrationFailure';
import UpdateModal from './UpdateModal';

const SuppliersPage = ({ suppliers, fetchSuppliers }) => {
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [registrationError, setRegistrationError] = useState('');
  const [registeredSupplierName, setRegisteredSupplierName] = useState('');
  const [selectedSupplierForDelete, setSelectedSupplierForDelete] = useState('');
  const [selectedSupplierForUpdate, setSelectedSupplierForUpdate] = useState('');

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const onSelectSupplierForDelete = (supplierName) => {
    setSelectedSupplierForDelete(supplierName);
    setSelectedSupplierForUpdate('');
  };

  const onSelectSupplierForUpdate = (supplierName) => {
    setSelectedSupplierForDelete('');
    setSelectedSupplierForUpdate(supplierName);
  };

  const handleRegistrationComplete = (supplierName, error) => {
    setRegistrationComplete(true);
    setRegisteredSupplierName(supplierName);
    setRegistrationError(error);
  };

  const handleResetRegistrationPage = () => {
    setRegistrationComplete(false);
    setRegistrationError('');
    setRegisteredSupplierName('');
    setSelectedSupplierForDelete('');
  };

  const handleModalClose = () => {
    setSelectedSupplierForDelete('');
    setSelectedSupplierForUpdate('');
  };

  const getRegistrationCompletePage = () => {
    return registrationError ? (
      <RegistrationFailure resetRegistration={handleResetRegistrationPage} error={registrationError} />
    ) : (
      <RegistrationSuccess resetRegistration={handleResetRegistrationPage} supplierName={registeredSupplierName} />
    );
  };

  const supplierForUpdate = suppliers.find(({ supplierName }) => supplierName === selectedSupplierForUpdate) || null;

  return (
    <div>
      {!registrationComplete ? (
        <div>
          <div css={container}>
            <SupplierRegistrationForm onRegistrationComplete={handleRegistrationComplete} />
          </div>
          <DeleteModal supplierName={selectedSupplierForDelete} onClose={handleModalClose} />
          <UpdateModal supplier={supplierForUpdate} onClose={handleModalClose} />
          <SuppliersList
            suppliers={suppliers}
            setSupplierForDelete={onSelectSupplierForDelete}
            setSupplierForUpdate={onSelectSupplierForUpdate}
          />
          <UseInterval callback={fetchSuppliers} delay={3e3} />
        </div>
      ) : (
        getRegistrationCompletePage()
      )}
    </div>
  );
};

export default SuppliersPage;

const container = css`
  padding-left: 10%;
  width: 80%;
`;
