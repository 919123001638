import qs from 'qs'

const globalHeaders = {}

export function setGlobalHeader(name, value) {
  globalHeaders[name] = value
}

function processError(r: Response, contentType: string) {
  if (contentType === 'application/json') {
    return r
      .json()
      .then(res => {
        console.log(res.message)
        const error = new Error(res ? res.message : r.status)
        error.status = r.status
        throw error
      })
      .catch(err => {
        console.log('http.processError CATCH', err)
      })
  }
  return r.text().then(res => {
    console.log({ res })
    const error = new Error(JSON.parse(res).message)
    error.status = r.status
    throw error
  })
}

function processResponse(r: Response): Promise<any> {
  const contentType = r.headers.get('content-type')
  if (!r.ok) {
    return processError(r, contentType)
  }
  if (contentType === 'application/json') {
    return r.json()
  } else if (contentType === 'application/octet-stream') {
    return r.blob()
  } else if (r.body instanceof ReadableStream) {
    return r.json()
  }
  return r
}

export default {
  post(url, payload, headers) {
    return fetch(url, {
      method: 'POST',
      headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
      body: JSON.stringify(payload)
    }).then(processResponse)
  },
  get(url, query) {
    if (query) {
      const queryString = qs.stringify(query)
      url = url + '?' + queryString
    }

    return fetch(url, {
      headers: globalHeaders
    }).then(processResponse)
  },
  delete(url) {
    return fetch(url, {
      method: 'DELETE',
      headers: globalHeaders
    }).then(processResponse)
  },
  put(url, payload, headers) {
    return fetch(url, {
      method: 'PUT',
      headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
      body: JSON.stringify(payload)
    }).then(processResponse)
  }
}
