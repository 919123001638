/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { NextGen } from '@nike/epic-react-ui'
import DeployGGGroupForm  from './DeployGGGroupForm'
import { useState } from 'react'
import DeploymentFailure from './DeploymentFailure'
import DeploymentSuccess from './DeploymentSuccess'

function DeployGGGroupPage(props) {
  const [deploymentComplete, setDeploymentComplete] = useState<boolean>(false)
  const [deploymentError, setDeploymentError] = useState('');

  function handleDeploymentComplete() {
    setDeploymentComplete(true)
  }

  function handleResetDeploymentPage() {
    setDeploymentComplete(false)
    setDeploymentError('')
  }


  function getDeploymentCompletePage() {
    return deploymentError ? (
      <DeploymentFailure resetDeployment={handleResetDeploymentPage} error={deploymentError} />
    ) : (
      <DeploymentSuccess resetDeployment={handleResetDeploymentPage}/>
    );
  }

  return (
    <div>
      {deploymentComplete ? (
        getDeploymentCompletePage()
      ) : (
        <DeployGGGroupForm
            onDeploymentComplete={handleDeploymentComplete}
            region={props.region}
            version={props.version} />
      )}
    </div>
  )
}


export default DeployGGGroupPage;




