import { css, jsx } from '@emotion/react'
import React, { Component, ChangeEvent, SyntheticEvent, useEffect, useState, useRef } from 'react'
import { DeployGGGroupButton } from './DeployGGGroupButton'
import { DeploymentResult } from './types'
import ErrorMessage from '../components/ErrorMessage'
import { deployGGGroup } from '../util/apiUtil'
import { mediumText, buttonGroupStyle } from '../commonStyles'

type DeployGGGroupProps = {
  onDeploymentComplete: (result: DeploymentResult) => void
  region: null | string
  version: null | string
}

function DeployGGGroupForm(props: DeployGGGroupProps) {
  const [errorMessage, setErrorMessage] = useState('')
  
  async function handleSubmit(event: SyntheticEvent) {

    let errorMessageResp = ''
    if (props.version != 'v2') {
      errorMessageResp = 'Incorrect Version selected' 
      setErrorMessage(errorMessageResp)
      return
    }
    const result = await deployGGGroup()
    if (result == undefined) {
      errorMessageResp = 'GG Deployment unsuccessful'
      setErrorMessage(errorMessageResp)
      return
    } 

    props.onDeploymentComplete(result)
    event.persist()
  }

  return (
    <div style={{ display: 'table', width: '100%', paddingTop: '50px' }}>
        <div className='epic-font-brand' css={mediumText}>
            This will Create/Update latest IOT GG deployments for all edge core devices in this environment
          </div>
          <div css={buttonGroupStyle}>
          <DeployGGGroupButton type='Green Grass' handleSubmit={handleSubmit} />
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
      </div>
   
  )
}

export default DeployGGGroupForm

