import React from 'react'
import AboutPage from '../about/AboutPage'
import { Redirect } from 'raviger'
import DeviceRegistrationContainer from '../registration/DeviceRegistrationContainer'
import DevicesContainer from '../devices/DevicesContainer'
import SuppliersPageContainer from '../suppliers/SuppliersPageContainer'
import AdminContainer from '../admin/AdminContainer'
import GroupRegistrationPageContainer from '../groups/GroupRegistrationPageContainer'
const routes = {
  '/': () => <Redirect to='/home' />,
  '/home': () => <DevicesContainer />,
  '/device-registration': () => <DeviceRegistrationContainer />,
  '/group-registration': () => <GroupRegistrationPageContainer />,
  '/suppliers': () => <SuppliersPageContainer />,
  '/admin': () => <AdminContainer />
  // TODO: Can add About page back in once we have useful info on it
  // '/about': () => <AboutPage />
}

export default routes
