import { createAction } from '@nike/redux-action-utils'
import { listGroups } from '../util/apiUtil'

const SET_GROUPS = 'SET_GROUPS'
export const setGroups = createAction(SET_GROUPS)

export async function fetchGroups(dispatch) {
  const response = await listGroups()
  const groupResponse = response.groups || []
  const groups = groupResponse.map(g => ({
    ...g,
    port: g.port?.toString()
  }))

  dispatch({
    type: setGroups.type,
    payload: groups
  })
}
