/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'
import ReactSelect from 'react-select'

const versionStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', minWidth: '180px' })
}

export default ({ version, options, onChange }) => (
  <div css={versionDropdown}>
    <ReactSelect
      className='alt-version-select'
      classNamePrefix='version-select'
      options={options}
      onChange={onChange}
      value={version}
      styles={versionStyle}
    />
  </div>
)

const versionDropdown = css`
  padding-left: 30px;
`
