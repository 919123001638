/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Component, SyntheticEvent, ChangeEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, Section, NextGen } from '@nike/epic-react-ui'
import { updateSupplier, createRegisterSupplierPayload, Supplier } from '../util/apiUtil'
import RestrictedTextInput from '../components/RestrictedTextInput'
import { registrationSectionTitle, themedButton } from '../commonStyles'
import {
  validateAdGroupName,
  validateLongitude,
  validateLatitude,
  latErrorMessage,
  longErrorMessage
} from '../registration/utils'
import { SupplierType, supplierTypeDropdownValues } from './types'
import Spacer from '../components/Spacer'
import { validateSupplierRegistrationPayload } from './utils'
import ErrorMessage from '../components/ErrorMessage'

type UpdateModalProps = {
  supplier: null | Supplier
  onClose: () => void
}

type UpdateModalState = {
  supplierType: SupplierType
  supplierCode: string
  latitude: string
  longitude: string
  valuesInitialized: boolean
  errorMessage: string
  [x: string]: any
}

class UpdateModal extends Component<UpdateModalProps, UpdateModalState> {
  constructor(props: UpdateModalProps) {
    super(props)
    this.state = {
      supplierType: SupplierType.DC,
      supplierCode: '',
      latitude: '',
      longitude: '',
      valuesInitialized: false,
      errorMessage: ''
    }
  }

  static getDerivedStateFromProps(props: UpdateModalProps, state: UpdateModalState) {
    if (!props.supplier || state.valuesInitialized) {
      return null
    }

    const { supplierCode, supplierType, location } = props.supplier

    const { latitude, longitude } = location

    return {
      supplierType,
      supplierCode,
      latitude,
      longitude,
      valuesInitialized: true
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  handleSelectSupplierType = (selected: SupplierType) => {
    this.setState({
      supplierType: selected
    })
  }

  handleUpdate = async () => {
    if (!this.props.supplier) {
      return
    }
    const { supplierName } = this.props.supplier
    const { supplierType, supplierCode, latitude, longitude } =
      this.state
    const payload = createRegisterSupplierPayload(
      supplierType,
      supplierCode,
      supplierName,
      latitude,
      longitude,
    )

    const errorMessage = validateSupplierRegistrationPayload(payload)

    if (errorMessage) {
      this.setState({
        errorMessage
      })
      return
    }

    await updateSupplier(payload)
    this.handleClose()
  }

  handleClose = () => {
    this.setState({
      valuesInitialized: false,
      errorMessage: ''
    })
    this.props.onClose()
  }

  render() {
    const { supplier } = this.props
    const {
      supplierCode,
      supplierType,
      latitude,
      longitude,
      errorMessage
    } = this.state

    const showModal = !!supplier

    return (
      <Modal modalSize='sm' onClose={this.handleClose} closeButton show={showModal}>
        <div aria-label='Update Modal'>
          {supplier && (
            <div>
              <Modal.Title>
                <h1>Update {supplier.supplierName}</h1>
              </Modal.Title>
              <Modal.Content>
                <div css={updateTextBox}>Update supplier: ({supplier.supplierName})?</div>
                <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
                  <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
                    <div className='epic-font-brand' css={registrationSectionTitle}>
                      General Information
                    </div>
                    <Section>
                      <NextGen.Select
                        required
                        helperText='Supplier Type'
                        options={supplierTypeDropdownValues}
                        value={supplierTypeDropdownValues.find(item => item.value === supplierType)}
                        onChange={this.handleSelectSupplierType}
                      />
                      <Spacer height={15} />
                      <RestrictedTextInput
                        required
                        onChange={this.handleChange}
                        name='supplierCode'
                        label='Supplier Code'
                        value={supplierCode}
                        validation={validateAdGroupName}
                        errorMessage={'Only 0-9, a-z, A-Z, ., _, and - permitted'}
                      />
                    </Section>
                  </div>
                  <div style={{ display: 'table-cell' }}>
                    <div className='epic-font-brand' css={registrationSectionTitle}>
                      Location
                    </div>
                    <Section>
                      <RestrictedTextInput
                        required
                        onChange={this.handleChange}
                        name='latitude'
                        label='Latitude'
                        value={latitude}
                        validation={validateLatitude}
                        errorMessage={latErrorMessage}
                        type={'number'}
                      />
                      <RestrictedTextInput
                        required
                        onChange={this.handleChange}
                        name='longitude'
                        label='Longitude'
                        value={longitude}
                        validation={validateLongitude}
                        errorMessage={longErrorMessage}
                        type={'number'}
                      />
                    </Section>
                  </div>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button css={updateButton} onClick={this.handleUpdate} aria-label='Confirm Update'>
                  Update
                </Button>
                <Button
                  theme={themedButton}
                  inverse
                  onClick={this.handleClose}
                  aria-label='Cancel Update'
                >
                  Cancel
                </Button>
              </Modal.Actions>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

export default UpdateModal

const updateButton = css`
  background-color: #fe0000;
`
const updateTextBox = css`
  text-align: center;
`
