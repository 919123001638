/** @jsxRuntime classic */
/** @jsx jsx */

import React, { Component, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button } from '@nike/epic-react-ui'
import { deleteSupplier } from '../util/apiUtil'
import { themedButton } from '../commonStyles'

type DeleteModalProps = {
  supplierName: string
  onClose: () => void
}

const DeleteModal = (props: DeleteModalProps) => {
  const { supplierName, onClose } = props
  const showModal = supplierName !== ''

  const handleDelete = async () => {
    props.onClose()
    await deleteSupplier(props.supplierName)
  }

  return (
    <Modal modalSize='sm' onClose={onClose} closeButton show={showModal}>
      <div aria-label='Delete Modal'>
        <Modal.Title>
          <h1>Delete {supplierName}</h1>
        </Modal.Title>
        <Modal.Content>
          <div css={deleteTextBox}>
            Are you sure you would like to delete this supplier ({supplierName})?
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button css={deleteButton} onClick={handleDelete} aria-label='Confirm Delete'>
            Delete
          </Button>
          <Button theme={themedButton} inverse onClick={onClose} aria-label='Cancel Delete'>
            Cancel
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

export default DeleteModal

const deleteButton = css`
  background-color: #fe0000;
`
const deleteTextBox = css`
  text-align: center;
`
