/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, buttonGroupStyle, accentButton, blueText } from '../commonStyles'
import { smallText } from '../commonStyles'


const RegistrationSuccess = (props: {
  adGroup: string
  groupName: string
  groupType: string | null
  resetRegistration: () => void
}) => {
  const { adGroup, groupName, resetRegistration } = props

  const nowUTC = new Date().toISOString()

  return (
    <div>
      <div className='epic-font-brand' css={largeText}>
        Group registration has been initiated!
      </div>
      <div css={smallText}>
        Registered <span css={blueText}>{groupName}</span> for <span css={blueText}>{adGroup}</span>{' '}
        at: <span css={blueText}>{nowUTC}</span>
      </div>
      <div css={buttonGroupStyle}>
        <IconButton type='arrow-backward' positionFirst onClick={resetRegistration}>
          Back to Groups Page
        </IconButton>
        <Button onClick={() => navigate('/device-registration')} css={accentButton}>
          Register Device
        </Button>
      </div>
    </div>
  )
}

export default RegistrationSuccess
