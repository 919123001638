import { createAction } from '@nike/redux-action-utils'
import { listSuppliers } from '../util/apiUtil'

const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const setSuppliers = createAction(SET_SUPPLIERS)

export async function fetchSuppliers(dispatch) {
  const response = await listSuppliers()
  dispatch({
    type: setSuppliers.type,
    payload: response.suppliers
  })
}
