import React, { Component, ChangeEvent, SyntheticEvent, useEffect, useState, useRef } from 'react'
import { BasicDeviceInformation } from './BasicDeviceFields'
import { TagDeviceInformation } from './TagDeviceFields'
import { RegisterButton } from './RegisterButton'
import { BrandName, ControlType, DeviceInListDevices, FacilityType, JSONPayload } from '../registration/types'
import {
  createJsonFromHooks,
  PresignedUrlResponse,
  ListSuppliersResponse,
  getSuppliers,
  getGroupSubscriptions,
  registerDevice
} from '../util/apiUtil'

import {
  BasicDeviceFields,
  TagDeviceFields,
  DeviceType,
  ReadpointType,
  RegistrationResult,
  DeviceTopicFields,
  CloudDeviceTopics,
  LocalDeviceTopics,
  SubscriptionType
} from './types'
import { validateJSONPayload } from './utils'
import ErrorMessage from '../components/ErrorMessage'
import config from '../config'
import ControlTypeInformation from './ControlTypeInformation'

export interface DeviceRegistrationFields
  extends BasicDeviceFields,
    TagDeviceFields,
    DeviceTopicFields {
  [x: string]: any
}

type DeviceRegistrationProps = {
  onRegistrationComplete: (result: RegistrationResult) => void
  deviceNames: null | string[]
  groupNames: null | string[]
  region: null | string
  version: null | string
}

const initialBasicDeviceFields: BasicDeviceFields = {
  deviceName: '',
  deviceType: DeviceType.TUNNEL_SCANNER,
  groupName: '',
}
const initialTagDeviceFields: TagDeviceFields = {
  serialNumber: '12345PP133',
  manufacturer: 'Zebra',
  model: 'FX7500',
  brandName: BrandName.NIKE,
  firmwareName: 'Ubuntu',
  firmwareVersion: '19.0.1',
  readpointType: ReadpointType.SHIPPING,
  controlType: ControlType.PRIMARY,
  subscriptionType: SubscriptionType.DEVICE_TO_EDGEVM,
  readpointValue: '1',
  supplierId: '',
  longitude: '37.88901',
  latitude: '60.34501',
  userDeviceAlias: '',
}

const initialCloudDeviceTopicFields: CloudDeviceTopics = {
  publisherAssociation: '',
  publisherMetric: '',
  subscriberAssociation: '',
  subscriberMetric: ''
}

const initialLocalDeviceTopicFields: LocalDeviceTopics = {
  subscribedTopics: [],
  allGroupTopics: []
}

function DeviceRegistrationForm(props: DeviceRegistrationProps) {
  const [basicDeviceFields, setBasicDeviceFields] =
    useState<BasicDeviceFields>(initialBasicDeviceFields)
  const [tagDeviceFields, setTagDeviceFields] = useState<TagDeviceFields>(initialTagDeviceFields)
  const [deviceSubscriptionType, setDeviceSubscriptionType] = useState<SubscriptionType>(
    SubscriptionType.DEVICE_TO_EDGEVM
  )
  const [facilityType, setFacilityType] = useState<FacilityType>(FacilityType.DC)
  const [localDeviceTopicFields, setLocalDeviceTopicFields] = useState<LocalDeviceTopics>(
    initialLocalDeviceTopicFields
  )
  const [cloudDeviceTopicFields, setCloudDeviceTopicFields] = useState<CloudDeviceTopics>(
    initialCloudDeviceTopicFields
  )
  const [supplierIdOptions, setSupplierIdOptions] = useState([] as any)
  const [errorMessage, setErrorMessage] = useState('')
  const [chosenTopicSubscriptions, setChosenTopicSubscriptions] = useState<string[]>([] as any)

  const prevRegion = usePrevious(props.region)
  const prevVersion = usePrevious(props.version)
  useEffect(() => {
    if (prevRegion && props.region !== prevRegion) {
      setBasicDeviceFields({
        ...basicDeviceFields
      })

      populateSuppliers()

      setTagDeviceFields({
        ...tagDeviceFields,
        supplierId: ''
      })
    }
  })

  useEffect(() => {
    if (supplierIdOptions.length == 0) {
      populateSuppliers()
    }
  })

 

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  async function populateSuppliers() {
    const resp = await getSuppliers()
    if (resp instanceof Error) {
      return
    }
    setSupplierIdOptions(
      resp.suppliers.map(supplier => ({
        value: supplier.supplierName,
        label: supplier.supplierName
      }))
    )
  }



  function handleChange(event: ChangeEvent<HTMLInputElement>, component: string) {
    const value = event.target.value
    const name = event.target.name
    if (component == 'TagDeviceFields') {
      setTagDeviceFields({ ...tagDeviceFields, [name]: value })
    } else if (component == 'BasicDeviceFields') {
      setBasicDeviceFields({ ...basicDeviceFields, [name]: value })
    }
  }

  async function handleSelect(
    selected: DeviceType | ReadpointType | string,
    selectedType: string,
    component: string
  ) {
    if (component == 'TagDeviceFields') {
      setTagDeviceFields({ ...tagDeviceFields, [selectedType]: selected })
    } else if (component == 'BasicDeviceFields') {
      if (selectedType === 'groupName' && deviceSubscriptionType === SubscriptionType.VM_TO_LOCAL_DEVICE) {
        const subscriptions = await getGroupSubscriptions(selected)
        setLocalDeviceTopicFields({
          ...localDeviceTopicFields,
          allGroupTopics: subscriptions
        })
      }
      setBasicDeviceFields({ ...basicDeviceFields, [selectedType]: selected })
    }
  }

  async function handleSubscriptionToggle(selected: SubscriptionType) {
    setDeviceSubscriptionType(selected)
    setTagDeviceFields({
      ...tagDeviceFields,
      subscriptionType: selected
    })
  }

  async function handleControlTypeToggle(selected: ControlType) {
    setTagDeviceFields({
      ...tagDeviceFields,
      controlType: selected
    })
  }

  async function handleFacilityTypeToggle(selected: FacilityType) {
    setFacilityType(selected)
    setTagDeviceFields({
      ...tagDeviceFields, 
      facilityType: selected
    })
  }

  async function handleSubmit(event: SyntheticEvent) {

    if (props.deviceNames?.includes(basicDeviceFields.deviceName)) {
      setErrorMessage('Device Name must be unique')
      return
    }

    if (tagDeviceFields.brandName !== BrandName.CONVERSE && props.version !== 'v1') {
      basicDeviceFields.deviceName = "None" //Nike and Jordan device names are created on the backend.
    }

    const jsonPayload = createJsonFromHooks(basicDeviceFields, tagDeviceFields)

    let errorMessageResp = validateJSONPayload(jsonPayload)
    if (errorMessageResp) {
      setErrorMessage(errorMessageResp)
      return
    }
    const result = await registerDevice(jsonPayload)

    if ('message' in result) {
      let resultAsError = result as Error
      errorMessageResp = 'Device registration unsuccessful'

      if (resultAsError.message) {
        errorMessageResp = resultAsError.message
      }

      setErrorMessage(errorMessageResp)
      return
    }

    props.onRegistrationComplete(result)
    event.persist()
  }

  return (
    <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
      <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
        <BasicDeviceInformation
          handleChange={handleChange}
          handleSelect={handleSelect}
          deviceType={basicDeviceFields.deviceType}
          deviceName={basicDeviceFields.deviceName}
          groupName={basicDeviceFields.groupName}
          groupNames={props.groupNames}
          isReadOnly={false}
          brand={tagDeviceFields.brandName}
          version={props.version}
        />
        <ControlTypeInformation
          controlType={tagDeviceFields.controlType}
          facilityType={facilityType}
          subscriptionType={deviceSubscriptionType}
          handleControlTypeToggle={handleControlTypeToggle}
          handleFacilityTypeToggle={handleFacilityTypeToggle}
          handleSubscriptionTypeToggle={handleSubscriptionToggle}
        />
      </div>
      <div style={{ display: 'table-cell' }}>
        <TagDeviceInformation
          handleChange={handleChange}
          handleSelect={handleSelect}
          controlType={tagDeviceFields.controlType}
          subscriptionType={tagDeviceFields.subscriptionType}
          brandName={tagDeviceFields.brandName}
          serialNumber={tagDeviceFields.serialNumber}
          manufacturer={tagDeviceFields.manufacturer}
          model={tagDeviceFields.model}
          firmwareName={tagDeviceFields.firmwareName}
          firmwareVersion={tagDeviceFields.firmwareVersion}
          readpointType={tagDeviceFields.readpointType}
          readpointValue={tagDeviceFields.readpointValue}
          supplierId={tagDeviceFields.supplierId}
          supplierIdOptions={supplierIdOptions}
          latitude={tagDeviceFields.latitude}
          longitude={tagDeviceFields.longitude}
          userDeviceAlias={tagDeviceFields.userDeviceAlias}
          isReadOnly={false}
        />
        <div
          style={{
            paddingLeft: '9%'
          }}
        >
          <RegisterButton type='Device' handleSubmit={handleSubmit} />
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
      </div>
    </div>
  )
}

export default DeviceRegistrationForm
