import { connect } from 'react-redux'
import { isReadOnlySelector } from '../auth/adGroupReducer'
import GroupsList from './GroupList'
import { groupsSelector } from './reducer'
import { fetchGroups } from './actions'
import { versionSelector } from '../multiversion/reducer'

const mapStateToProps = state => ({
  groups: groupsSelector(state),
  isReadOnly: isReadOnlySelector(state),
  version: versionSelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchGroups: () => dispatch(fetchGroups)
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupsList)
