import { CompassCalibrationOutlined } from '@material-ui/icons'
import { createAction } from '@nike/redux-action-utils'
import { getGroupsFromToken } from './apiUtil'

export const setLocations = createAction('SET_LOCATIONS')

export async function fetchLocations(dispatch) {
  const response = await getGroupsFromToken()

  dispatch({
    type: setLocations.type,
    payload: response.locations
  })
}
