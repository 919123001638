import { createAction } from '@nike/redux-action-utils'

export const SET_VERSION = createAction('SET_VERSION')

export function storeVersion(version) {
  return {
    type: SET_VERSION.type,
    value: version
  }
}
