import { setGroups } from './actions'

type groupsState = any[]
const defaultState = []

export default function groups(state: groupsState = defaultState, action) {
  switch (action.type) {
    case setGroups.type:
      return action.payload
    default:
      return state
  }
}

export const groupsSelector = state => state.groups
