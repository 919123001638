import { List, fromJS, Collection } from 'immutable'
import { Supplier } from '../util/apiUtil'

import { setSuppliers } from './actions'

type supplierState = List<string> | Collection<any, any>
const defaultState: List<string> = List()

export default function suppliers(state: supplierState  = defaultState, action) {
  state = fromJS(state)
  switch (action.type) {
    case setSuppliers.type:
      return action.payload
    default:
      return state
  }
}

export const suppliersSelector = (state): Supplier[] => state.suppliers
