import { connect } from 'react-redux'
import { isReadOnlySelector } from '../auth/adGroupReducer'
import SuppliersPage from './SuppliersPage'
import DevicesPage from '../devices/DevicesPage'
import { suppliersSelector } from './reducer'
import { fetchSuppliers } from './actions'

const mapStateToProps = state => ({
  suppliers: suppliersSelector(state),
  isReadOnly: isReadOnlySelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchSuppliers: () => dispatch(fetchSuppliers)
})

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersPage)
