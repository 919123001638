/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Component } from 'react'
import V2GroupRegistrationForm from './V2GroupRegistrationForm'
import V1GroupRegistrationForm from './V1GroupRegistrationForm'
import RegistrationSuccess from './RegistrationSuccess'
import {GroupRegistrationFieldsV2, V2GroupRegistrationResult, 
  GroupRegistrationFieldsV1, V1GroupRegistrationResult, 
  GroupType, GroupRegistrationFields } from './types'
import RegistrationFailure from './RegistrationFailure'
import GroupListContainer from './GroupListContainer'


type RegistrationPageState = {
  registrationComplete: boolean
  registrationResult: V2GroupRegistrationResult | V1GroupRegistrationResult | null
  
}

type RegistrationPageProps = {
  version: string | null
}

function instanceOfGroupResgistrationSuccess(object: any): object is GroupRegistrationFields {
  return 'groupName' in object
}
class GroupRegistrationPage extends Component<RegistrationPageProps, RegistrationPageState> {
  constructor(props: RegistrationPageProps) {
    super(props)
    console.log("Props ",props.version)
    this.state = {
      registrationComplete: false,
      registrationResult: null,
    }
  }

  handleV1RegistrationComplete = (result: V1GroupRegistrationResult) => {
    this.setState({
      registrationComplete: true,
      registrationResult: result
    })
  }

  handleV2RegistrationComplete = (result: V2GroupRegistrationResult) => {
    this.setState({
      registrationComplete: true,
      registrationResult: result
    })
  }

  handleResetRegistrationPage = () => {
    this.setState({
      registrationComplete: false,
      registrationResult: null
    })
  }

  getV1RegistrationCompletePage = () => {
    const { registrationResult } = this.state
    return instanceOfGroupResgistrationSuccess(registrationResult) ? (
      <RegistrationSuccess
        adGroup={registrationResult.adGroup}
        groupName={registrationResult.groupName}
        resetRegistration={this.handleResetRegistrationPage} 
        groupType={null}      />
    ) : (
      <RegistrationFailure
        error={registrationResult}
        resetRegistration={this.handleResetRegistrationPage}
      />
    )
  }

  getV2RegistrationCompletePage = () => {
    const { registrationResult } = this.state
    return instanceOfGroupResgistrationSuccess(registrationResult) ? (
      <RegistrationSuccess
        adGroup={registrationResult.adGroup}
        groupType={registrationResult.groupType}
        groupName={registrationResult.groupName}
        resetRegistration={this.handleResetRegistrationPage}
      />
    ) : (
      <RegistrationFailure
        error={registrationResult}
        resetRegistration={this.handleResetRegistrationPage}
      />
    )
  }

  render() {
    const { registrationComplete } = this.state
    return (
      <div>
        {registrationComplete ? (
          this.props.version == 'v1' ? this.getV1RegistrationCompletePage() : this.getV2RegistrationCompletePage()
        ) : (
          <div>
            <div css={selectLocationDevice}>
              { this.props.version == 'v1'? 
              <V1GroupRegistrationForm 
                onRegistrationComplete={this.handleV1RegistrationComplete} 
                version={this.props.version}/> :
                <V2GroupRegistrationForm 
                onRegistrationComplete={this.handleV2RegistrationComplete} 
                version={this.props.version}/>  
              }
            </div>
            <GroupListContainer />
          </div>
        )}
      </div>
    )
  }
}

export default GroupRegistrationPage


const selectLocationDevice = css`
  padding-left: 10%;
  width: 80%;
`
