/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, ChangeEvent, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, Spinner } from '@nike/epic-react-ui'
import { createUpdateGroupPayload, updateGroup } from '../util/apiUtil'
import { validateAdGroupName, validateIP, validatePort } from '../registration/utils'
import RestrictedTextInput from '../components/RestrictedTextInput'
import ErrorMessage from '../components/ErrorMessage'
import { Group } from './types'
import { themedButton } from '../commonStyles'

type UpdateModalProps = {
  group: null | Group
  onClose: () => void
}

type UpdateModalState = {
  updatedAdGroupId: string
  updatedIP: string
  updatedPort: string
  errorMessage: string
  isLoading: boolean,
  [x: string]: any
}

class UpdateModal extends Component<UpdateModalProps, UpdateModalState> {
  constructor(props: UpdateModalProps) {
    super(props)
    this.state = {
      updatedAdGroupId: props.group?.adGroupId || '',
      updatedIP: props.group?.ip || '',
      updatedPort: props.group?.port || '',
      errorMessage: '',
      isLoading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps: UpdateModalProps) {
    const newGroup = prevProps.group?.groupName !== this.props.group?.groupName
    if (newGroup) {
      this.setState({
        updatedAdGroupId: this.props.group?.adGroupId || '',
        updatedIP: this.props.group?.ip || '',
        updatedPort: this.props.group?.port || '',
        errorMessage: ''
      })
    }
  }

  getErrorMessage = () => {
    const { updatedAdGroupId, updatedIP, updatedPort } = this.state
    const validADGroup = validateAdGroupName(updatedAdGroupId)
    const validIP = validateIP(updatedIP)
    const validPort = validatePort(updatedPort)
    let errorMessage = ''

    if (!updatedAdGroupId?.length || !updatedIP?.length || !updatedPort?.length) {
      errorMessage = 'Required fields cannot be empty'
    } else if (!validADGroup) {
      errorMessage = 'Invalid AD Group Input'
    } else if (!validIP) {
      errorMessage = 'Invalid IP'
    } else if (!validPort) {
      errorMessage = 'Invalid Port'
    }

    return errorMessage
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  handleOpen = () => {
    this.setState({
      updatedAdGroupId: this.props.group?.adGroupId || '',
      updatedIP: this.props.group?.ip || '',
      updatedPort: this.props.group?.port || '',
      errorMessage: ''
    })
  }

  async handleSubmit(event: SyntheticEvent) {
    this.setState({ errorMessage: '' })
    this.setState({ isLoading: true })
    const errorMessage = this.getErrorMessage()

    if (errorMessage) {
      this.setState({ errorMessage })
      return
    }

    const { updatedGroupType, updatedAdGroupId, updatedIP, updatedPort } = this.state
    const { group, onClose } = this.props
    const groupName = group?.groupName

    if (!groupName) {
      return
    }
    const jsonPayload = createUpdateGroupPayload(
      groupName,
      updatedGroupType,
      updatedAdGroupId,
      updatedIP,
      updatedPort
    )
    let response: any = await updateGroup(groupName, jsonPayload)

    if (response.status !== 200 && response.status !== 201) {
      this.setState({ isLoading: false })
    }

    if (response.status === 409) {
      this.setState({ errorMessage: 'Group is in an invalid state. Try again in a few minutes.' })
      return
    }

    onClose()
  }

  render() {
    const { errorMessage, updatedAdGroupId, updatedIP, updatedPort } = this.state

    const { group, onClose } = this.props
    const groupName = group?.groupName

    return (
      <div>
        <Modal
          modalSize='md'
          onClose={onClose}
          onOpen={this.handleOpen}
          closeButton
          show={!!groupName}
        >
          <div aria-label='update group modal'>
            <Modal.Title>
              <h1>Update Group: {groupName}</h1>
            </Modal.Title>
            <Modal.Content>
              <div>
                <RestrictedTextInput
                  required
                  onChange={this.handleChange}
                  name='updatedAdGroupId'
                  label='AD Group'
                  value={updatedAdGroupId}
                  validation={validateAdGroupName}
                  errorMessage={'Only 0-9, a-z, A-Z, ., _, and - permitted'}
                  placeholder={'AD Group Name'}
                />
                <RestrictedTextInput
                  required
                  onChange={this.handleChange}
                  name='updatedIP'
                  label='IP'
                  value={updatedIP || ''} // some groups do not have IP set and the input does not want null
                  validation={validateIP}
                  errorMessage={'Only IP address - permitted'}
                  placeholder={'i.e. 127.0.0.1'}
                />
                <RestrictedTextInput
                  required
                  type='number'
                  onChange={this.handleChange}
                  name='updatedPort'
                  label='Port'
                  value={updatedPort || ''} // some groups do not have port set and the input does not want null
                  validation={validatePort}
                  errorMessage={'Only 0-65535 - permitted'}
                  placeholder={'i.e. 8080'}
                />
              </div>
              <Modal.Actions>
                <Button onClick={this.handleSubmit} theme={this.state.isLoading ? '' : themedButton} aria-label='update group' disabled={this.state.isLoading}>
                  { this.state.isLoading ? <Spinner /> : 'Update Group' }
                </Button>
                <Button
                  onClick={onClose}
                  theme={themedButton}
                  inverse
                  aria-label='close update modal'
                >
                  Cancel
                </Button>
              </Modal.Actions>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </Modal.Content>
          </div>
        </Modal>
      </div>
    )
  }
}

export default UpdateModal
