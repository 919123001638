/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, ToggleSwitch } from '@nike/epic-react-ui'
import { deployGroup } from '../util/apiUtil'
import { themedButton } from '../commonStyles'

type DeployGroupModalProps = {
  groupName: string
  onClose: () => void
}

type DeployGroupModalState = {
  lambdaUpdate: boolean
}

class DeployGroupModal extends Component<DeployGroupModalProps, DeployGroupModalState> {
  constructor(props: DeployGroupModalProps) {
    super(props)
    this.state = {
      lambdaUpdate: false
    }
    this.handleDeploy = this.handleDeploy.bind(this)
  }

  async handleDeploy() {
    await deployGroup(this.props.groupName, this.state.lambdaUpdate)
    this.props.onClose()
  }

  onSelectLambdaUpdate = (selected: boolean) => {
    this.setState({
      lambdaUpdate: selected
    })
  }

  render() {
    const { groupName, onClose } = this.props

    return (
      <div>
        <Modal modalSize='md' onClose={onClose} closeButton show={!!groupName}>
          <div aria-label='deploy group modal'>
            <Modal.Title>
              <h1>Deploy Groups</h1>
            </Modal.Title>
            <Modal.Content>
              <div css={deployTextbox}>Are you sure you want to deploy {groupName}?</div>
              <ToggleSwitch
                options={[
                  { value: true, label: 'λ' },
                  { value: false, label: 'Off' }
                ]}
                value={this.state.lambdaUpdate}
                onChange={this.onSelectLambdaUpdate}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleDeploy} aria-label='deploy group' theme={themedButton}>
                Deploy Group
              </Button>
              <Button
                theme={themedButton}
                onClick={onClose}
                inverse
                aria-label='close deploy modal'
              >
                Cancel
              </Button>
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    )
  }
}

export default DeployGroupModal

const deployTextbox = css`
  text-align: center;
`
