import { V1GroupRegistrationResult, V2GroupRegistrationResult, GroupType } from '../groups/types'
import { DeviceRegistrationFields } from '../registration/DeviceRegistrationForm'
import {
  InnerJSON,
  JSONPayload,
  GroupNames,
  DeviceInListDevices,
  DeviceTopicFields,
  BasicDeviceFields,
  TagDeviceFields,
  SubscriptionType,
  ReadpointType,
  ControlType,
  FacilityType
} from '../registration/types'
import http from '../util/http'
import config from '../config'
import { store } from '../index'
import { SupplierType } from '../suppliers/types'
import {DeploymentResult} from '../admin/types'

interface ListDevicesResponseBody {
  devices: DeviceInListDevices[]
}

export interface GetDeviceResponseBody {
  deviceId: string
  deviceName: string
  deviceType?: string
  serialNumber: string
  brand: string
  manufacturer: string
  model: string
  firmwareName: string
  firmwareVersion: string
  readpointType: ReadpointType
  readpointValue: string
  supplierId: string
  locationLongitude: string
  locationLatitude: string
  createdAt: string
  updatedAt: string
  groupName: string
  subscriptionType: SubscriptionType
  localDeviceSubscriptions?: string[]
  publisherAssociationTopic?: string
  publisherMetricTopic?: string
  subscriberAssociationTopic?: string
  subscriberMetricTopic?: string
  controlType?: ControlType
  facilityType?: FacilityType
  userDeviceAlias: string
}

export interface Certs {
  certificatePem: string
  certificatePrivateKey: string
  certificatePublicKey: string
  endpoints: {
    [x: string]: string
  }
}

export interface PresignedUrlResponse {
  url: string
}

export interface RegisterDeviceResponse {
  deviceName: string
}

export type RegisterV1GroupPayload = {
  adGroupId: string
  groupNameId: string
  ip: string
  port: number
}

export type RegisterV2GroupPayload = {
  adGroupId: string
  groupType: GroupType
  groupNameId: string
  ip: string
  port: number
}

export type RegisterSupplierPayload = {
  supplierType: SupplierType
  supplierCode: string
  supplierName: string
  location: {
    latitude: string
    longitude: string
  }
}

export type UpdateGroupPayload = {
  groupName: string
  groupType: GroupType
  adGroupId: string
  ip: string
  port: number
}

export type DeleteGroupPayload = {
  groupName: string
}

export interface ListDevicesRequestBody {
  groupNames: string[] | null
}
export interface DeploymentStatusResponse {
  deploymentStatus: string
}

export interface ListSuppliersResponse {
  suppliers: Supplier[]
}

export type Supplier = {
  supplierName: string
  supplierType: SupplierType
  supplierCode: string
  location: {
    latitude: string
    longitude: string
  }
}

export function getApiUrl() {
  const region = store.getState().multiregion
  const version = store.getState().multiversion
  return `https://${region}${config.apiUrl}/api/${version}`;
  //return `http://localhost:9001/lambda-url/api/api/v1`;
}

export function createJsonFromHooks(
  basic: BasicDeviceFields,
  tag: TagDeviceFields,
) {
  const nowLocal = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10) // If we decicde to display local time down the line
  const nowUTC = new Date().toISOString().slice(0, 10)
  let innerJson: InnerJSON = {
    serialNumber: tag.serialNumber,
    manufacturer: tag.manufacturer,
    model: tag.model,
    brand: tag.brandName,
    firmwareName: tag.firmwareName,
    firmwareVersion: tag.firmwareVersion,
    readpointType: tag.readpointType,
    readpointValue: tag.readpointValue,
    supplierId: tag.supplierId,
    locationLatitude: tag.latitude,
    locationLongitude: tag.longitude,
    groupName: basic.groupName,
    controlType: tag.controlType || ControlType.PRIMARY,
    facilityType: tag.facilityType || FacilityType.DC,
    subscriptionType: tag.subscriptionType || SubscriptionType.DEVICE_TO_EDGEVM,
    userDeviceAlias: tag.userDeviceAlias
  }

  const json: JSONPayload = {
    deviceName: basic.deviceName,
    deviceType: basic.deviceType,
    groupName: basic.groupName,
    deviceTags: innerJson
  }

  return json
}

//Eventually when UpdateModal becomes functional component, it can use the same function as above
export function createJsonFromState(state: DeviceRegistrationFields, updateOnly: boolean) {
  const nowLocal = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10) // If we decicde to display local time down the line
  const nowUTC = new Date().toISOString().slice(0, 10)
  const innerJson: InnerJSON = !updateOnly ? {
    serialNumber: state.serialNumber,
    manufacturer: state.manufacturer,
    model: state.model,
    firmwareName: state.firmwareName,
    firmwareVersion: state.firmwareVersion,
    readpointType: state.readpointType,
    readpointValue: state.readpointValue,
    supplierId: state.supplierId,
    locationLatitude: state.latitude,
    locationLongitude: state.longitude,
    groupName: state.groupName,
    controlType: state.controlType || ControlType.PRIMARY,
    facilityType: state.facilityType || FacilityType.DC,
    subscriptionType: state.subscriptionType || SubscriptionType.DEVICE_TO_EDGEVM,
    userDeviceAlias: state.userDeviceAlias
  } :
  {
    serialNumber: state.serialNumber,
    manufacturer: state.manufacturer,
    model: state.model,
    brand: state.brandName,
    firmwareName: state.firmwareName,
    firmwareVersion: state.firmwareVersion,
    readpointType: state.readpointType,
    readpointValue: state.readpointValue,
    supplierId: state.supplierId,
    locationLatitude: state.latitude,
    locationLongitude: state.longitude,
    groupName: state.groupName,
    userDeviceAlias: state.userDeviceAlias
  }
  const json: JSONPayload = {
    deviceName: state.deviceName,
    deviceType: state.deviceType,
    groupName: state.groupName,
    deviceTags: innerJson
  }

  return json
}

export function createRegisterV1GroupPayload(
  adGroupId: string,
  groupNameId: string,
  ip: string,
  port: string
): RegisterV1GroupPayload {
  return {
    adGroupId,
    groupNameId,
    ip,
    port: parseInt(port, 10)
  }
}

export function createRegisterV2GroupPayload(
  adGroupId: string,
  groupType: GroupType,
  groupNameId: string,
  ip: string,
  port: string
): RegisterV2GroupPayload {
  return {
    adGroupId,
    groupType,
    groupNameId,
    ip,
    port: parseInt(port, 10)
  }
}

export function createUpdateGroupPayload(
  groupName: string,
  groupType: GroupType,
  adGroupId: string,
  ip: string,
  port: string
): UpdateGroupPayload {
  return {
    groupName,
    groupType,
    adGroupId,
    ip,
    port: parseInt(port, 10)
  }
}

export function createDeleteGroupPayload(groupName: string): DeleteGroupPayload {
  return {
    groupName
  }
}

export async function registerDevice(
  payload: JSONPayload
): Promise<RegisterDeviceResponse | Error> {
  try {
    const apiUrl = getApiUrl()
    const { deviceTags: { ...restDeviceTags }, groupName, ...restPayload } = payload;
    const enrichedPayload = {
      ...restPayload,
      deviceTags: {
        ...restDeviceTags
      },
    };
    const response = await http.post(`${apiUrl}/devices`, { ...enrichedPayload })
    return response
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export async function updateDevice(payload: JSONPayload): Promise<PresignedUrlResponse | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.put(`${apiUrl}/devices/${payload.deviceName}`, {
      ...payload,
      deviceName: undefined,
      groupName: undefined,
      deviceTags: {
        ...payload.deviceTags,
        groupName: undefined
      }
    })
    return response
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export async function registerGroup(
  payload: RegisterV1GroupPayload | RegisterV2GroupPayload
): Promise<V1GroupRegistrationResult | V2GroupRegistrationResult | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.post(`${apiUrl}/groups`, payload)
    return response
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export function createRegisterSupplierPayload(
  supplierType: SupplierType,
  supplierCode: string,
  supplierName: string,
  latitude: string,
  longitude: string,
): RegisterSupplierPayload {
  return {
    supplierType,
    supplierName,
    supplierCode,
    location: {
      latitude,
      longitude
    }
  }
}

export async function registerSupplier(payload: RegisterSupplierPayload): Promise<string | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.post(`${apiUrl}/suppliers`, payload)
    return response
  } catch (err: any) {
    return err
  }
}

export async function updateSupplier(payload: RegisterSupplierPayload): Promise<string | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.put(`${apiUrl}/suppliers/${payload.supplierName}`, payload)
    return response
  } catch (err: any) {
    return err
  }
}

// Currently there is no delete supplier route in the API

export async function deleteSupplier(supplierName: string): Promise<string | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.delete(`${apiUrl}/suppliers/${supplierName}`)
    return response
  } catch (err: any) {
    return err
  }
}

export async function updateGroup(
  groupName: string,
  payload: UpdateGroupPayload
): Promise<string | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.put(`${apiUrl}/groups/${groupName}`, {
      ...payload,
      groupName: undefined
    })
    return response
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export async function deleteGroup(payload: DeleteGroupPayload): Promise<string | Error> {
  try {
    const apiUrl = getApiUrl()
    const response = await http.delete(`${apiUrl}/groups/${payload.groupName}`)
    return response
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export async function getGroupsFromToken(): Promise<GroupNames> {
  const apiUrl = getApiUrl()
  const response: GroupNames = await http.get(`${apiUrl}/locations`);
  const responseData: GroupNames = response.data;
  return responseData
}

type ListGroupResponse = {
  count: number
  groups: Group[]
}

interface Group {
  adGroupId: string
  groupName: string
  ip: string
  port: number
  deploymentStatus: string
  groupStatus: string
}

export async function listSuppliers(): Promise<ListSuppliersResponse> {
  const apiUrl = getApiUrl()
  const response: ListSuppliersResponse = await http.get(`${apiUrl}/suppliers`)

  return response
}

export async function listGroups(): Promise<ListGroupResponse> {
  const apiUrl = getApiUrl()
  const response: ListGroupResponse = await http.get(`${apiUrl}/groups`)
  return response
}

export async function listDevices(
  payload: ListDevicesRequestBody
): Promise<ListDevicesResponseBody> {
  try {
    const apiUrl = getApiUrl()  
    let groupNames = payload.groupNames ? payload.groupNames : ''
    if (groupNames.length > 0) {
      const response: ListDevicesResponseBody = await http.get(
        `${apiUrl}/devices?groupName=${groupNames}`
      )
      return response
    } else {
      const response: ListDevicesResponseBody  = await http.get(
      `${apiUrl}/devices`
    )
    return response
  }    
  } catch (err: any) {
    console.log(err.toString())
    return err
  }
}

export async function getDevice(deviceName: string): Promise<GetDeviceResponseBody> {
    const apiUrl = getApiUrl()
    const response = await http.get(`${apiUrl}/devices/${deviceName}`)
    return response
  }

  export async function deleteDevice(deviceName: string): Promise<void> {
    try {
      const apiUrl = getApiUrl()
      return await http.delete(`${apiUrl}/devices/${deviceName}`)
    } catch (err: any) {
      return err
    }
  }

  export async function getDeviceCerts(deviceName: string): Promise<PresignedUrlResponse> {
    const apiUrl = getApiUrl()
    const response = await http.get(`${apiUrl}/devices/${deviceName}/certs`)
    return response
  }

  export async function getGroupCerts(groupName: string): Promise<PresignedUrlResponse> {
    const apiUrl = getApiUrl()
    const response = await http.get(`${apiUrl}/groups/${groupName}/certs`)
    return response
  }

  export async function deployGroup(
    groupName: string,
    lambdaUpdate: boolean
  ): Promise<DeploymentStatusResponse> {
    const apiUrl = getApiUrl()
    const lambdaArg = `lambda=${lambdaUpdate.toString()}`
    const response = await http.post(`${apiUrl}/groups/${groupName}/deploy?${lambdaArg}`)
    return response
  }

  export async function getSuppliers(): Promise<ListSuppliersResponse> {
    try {
      const apiUrl = getApiUrl()
      const response = await http.get(`${apiUrl}/suppliers`)
      return response
    } catch (err: any) {
      console.log(err.toString())
      return { suppliers: [] }
    }
  }

  export async function getGroupSubscriptions(groupName: string): Promise<string[]> {
    const apiUrl = getApiUrl()
    const response = await http.get(`${apiUrl}/groups/${groupName}/subscriptions`)
    return response
  }

  
  export async function deployGGGroup(): Promise<DeploymentResult | Error> {
    const apiUrl = getApiUrl()
    const response = await http.post(`${apiUrl}/greengrass/deployment`, {})
    return response
  }