interface PresignedUrlResponse {
  url: string
}

interface RegisterDeviceResponse {
  deviceName: string
}

export enum DeviceType {
  RFID_READER = 'rfid_reader',
  TUNNEL_SCANNER = 'tunnel_scanner',
  HANDHELD = 'handheld',
  SCANNER = 'scanner'
}

export enum BrandName {
  NIKE = 'NIKE',
  JORDAN = 'JORDAN',
  CONVERSE = 'CONVERSE'
}

export enum DeviceStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

export enum GroupStatus {
  COMPLETED = 'COMPLETED',
}

export enum ReadpointType {
  SHIPPING = 'SHIPPING',
  PRE_SHIPPING = 'PRE_SHIPPING',
  PACKING_STATION = 'PACKING_STATION'
}

export enum SubscriptionType {
  VM_TO_LOCAL_DEVICE = 'VMToLocalDevice',
  DEVICE_TO_EDGEVM = 'DeviceToEdgeVM',
  CLOUD_TO_IOT = 'Cloud'
}

export enum ControlType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export enum FacilityType {
  FACTORY = 'factory',
  DC = 'dc'
}

export const deviceTypeDropdownValues: DropDownObject[] = [
  { value: DeviceType.RFID_READER, label: 'RFID Reader' },
  { value: DeviceType.TUNNEL_SCANNER, label: 'Tunnel Scanner' },
  { value: DeviceType.HANDHELD, label: 'Handheld' },
  { value: DeviceType.SCANNER, label: 'Scanner' }
]

export const brandNameDropdownValues: DropDownObject[] = [
  { value: BrandName.NIKE, label: 'Nike' },
  { value: BrandName.JORDAN, label: 'Jordan' },
  { value: BrandName.CONVERSE, label: 'Converse' },
]

export interface DropDownObject {
  value: DeviceType | ReadpointType | string
  label: string
}

export type InnerJSON = {
  serialNumber: string
  manufacturer: string
  model: string
  brand?: string
  firmwareName: string
  firmwareVersion: string
  controlType?: ControlType
  facilityType?: FacilityType
  readpointType: ReadpointType
  readpointValue: string
  supplierId: string
  locationLongitude: string
  locationLatitude: string
  groupName: string
  subscriptionType?: string
  userDeviceAlias: string
}

export interface JSONPayload extends BasicDeviceFields {
  deviceTags: InnerJSON
}

export interface BasicDeviceFields {
  deviceName: string
  deviceType: DeviceType | null
  groupName: string
}

export interface LocalDeviceTopics {
  subscribedTopics: string[]
  allGroupTopics: string[]
}

export interface CloudDeviceTopics {
  publisherAssociation: string
  publisherMetric: string
  subscriberAssociation: string
  subscriberMetric: string
}

export interface DeviceTopicFields {
  subscriptionType: SubscriptionType
  localDeviceTopics: LocalDeviceTopics | undefined
  cloudDeviceTopics: CloudDeviceTopics | undefined
}

export interface TagDeviceFields {
  serialNumber: string
  manufacturer: string
  model: string
  brandName: string
  firmwareName: string
  firmwareVersion: string
  readpointType: ReadpointType
  readpointValue: string
  supplierId: string
  longitude: string
  latitude: string
  controlType?: ControlType
  facilityType?: FacilityType
  subscriptionType: SubscriptionType,
  userDeviceAlias: string
}

export interface DeviceInListDevices {
  deviceId: string
  deviceName: string
  firmwareName: string
  firmwareVersion: string
  locationLatitude: string
  locationLongitude: string
  manufacturer: string
  model: string
  readpointType: string
  readpointValue: string
  serialNumber: string
  thingARN: string
  updatedAt: string
  createdAt: string
  controlType?: ControlType
  userDeviceAlias: string
}

export type RegistrationResult = RegisterDeviceResponse | Error

export type GroupNames = {
  data: GroupNames
  locations: string[]
}

export type GroupFields = {
  adGroupId: string
  groupName: string
  ip: string
  port: number
  deploymentStatus: string
  groupStatus: string
}
