import { Map, fromJS } from 'immutable'

import { storeRegion, SET_REGION } from './actions'

// Default the region to the last selected region (which survives browser restarts)
// If the user has never selected a region before, default to us-west-2
const defaultRegion = localStorage.getItem('lastSelectedRegion') || 'us-west-2'

export default function multiregion(state = defaultRegion, action) {
  switch (action.type) {
    case SET_REGION.type:
      return action.value
    default:
      return state
  }
}
export const regionSelector = (state): string => state.multiregion
