/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, buttonGroupStyle } from '../commonStyles'

const DeploymentSuccess = (props: { resetDeployment: () => void }) => {
  const { resetDeployment } = props
  return (
    <div aria-label='Greengrass Deployment Success'>
      <div className='epic-font-brand' css={largeText}>
        Successfully submitted Greengrass V2 deployment
      </div>
      <div css={buttonGroupStyle}>
        <IconButton type='arrow-backward' positionFirst onClick={resetDeployment}>
          Back to Admin Page
        </IconButton>
      </div>
    </div>
  )
}

export default DeploymentSuccess
