import React, { useState, useEffect, useRef } from 'react'

type SavedCallback = {
  current: any
  [x: string]: any
}

export default function useInterval(callback: () => any, delay: number | null) {
  const savedCallback: SavedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
