/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, mediumText, smallMargin } from '../commonStyles'

type RegistrationFailureProps = {
  resetRegistration: () => void
  error: string
}

const RegistrationFailure = (props: RegistrationFailureProps) => {
  const { error } = props
  return (
    <div aria-label='Supplier Registration Failure'>
      <div className='epic-font-brand' css={largeText}>
        Supplier registration failure :(
      </div>
      <div className='epic-font-brand' css={mediumText}>
        Failed to register supplier. Error: {error}
      </div>
      <Button onClick={props.resetRegistration} css={smallMargin}>
        Supplier Registration Page
      </Button>
    </div>
  )
}

export default RegistrationFailure
