import { connect } from 'react-redux'
import { isReadOnlySelector } from '../auth/adGroupReducer'
import { regionSelector } from '../multiregion/reducer'
import DeployGGGroupPage from './DeployGGGroupPage'
import { versionSelector } from '../multiversion/reducer'


const mapStateToProps = state => ({
  isReadOnly: isReadOnlySelector(state),
  region: regionSelector(state),
  version: versionSelector(state)
})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(DeployGGGroupPage)
