import { List, fromJS, Collection } from 'immutable'

import { setLocations } from './actions'

type locationsState = List<string> | Collection<any, any>
const defaultState: List<string> = List()

export default function locations(state: locationsState = defaultState, action) {
  state = fromJS(state)
  switch (action.type) {
    case setLocations.type:
      return action.payload
    default:
      return state
  }
}

export const locationsSelector = (state): string[] => state.locations
