/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button } from '@nike/epic-react-ui'
import { getGroupCerts } from '../util/apiUtil'
import { themedButton } from '../commonStyles'

type DownloadModalProps = {
  groupName: string
  onClose: () => void
}

type DownloadModalState = {
  presignedUrl: null | string
}

class DownloadModal extends Component<DownloadModalProps, DownloadModalState> {
  constructor(props: DownloadModalProps) {
    super(props)
    this.state = {
      presignedUrl: null
    }
    this.handleDownload = this.handleDownload.bind(this)
  }

  async componentDidMount() {
    await this.fetchCertsUrl()
  }

  async componentDidUpdate() {
    await this.fetchCertsUrl()
  }

  async fetchCertsUrl() {
    const { groupName } = this.props
    if (this.state.presignedUrl || !groupName) {
      return
    }
    const { url } = await getGroupCerts(groupName)
    this.setState({ presignedUrl: url })
  }

  async handleDownload() {
    if (!this.state.presignedUrl) {
      return
    }
    window.open(this.state.presignedUrl)
    this.props.onClose()
  }

  render() {
    const { onClose, groupName } = this.props
    return (
      <div>
        <Modal modalSize='sm' onClose={onClose} closeButton show={!!groupName}>
          <div aria-label='download group modal'>
            <Modal.Title>
              <h1>Download Certs</h1>
            </Modal.Title>
            <Modal.Content>
              <div css={deleteTextBox}>Download Certs for {this.props.groupName}?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                aria-label='download group'
                css={deleteButton}
                onClick={this.handleDownload}
                disabled={!this.state.presignedUrl}
                theme={themedButton}
              >
                Download
              </Button>
              <Button
                onClick={onClose}
                aria-label='close download modal'
                theme={themedButton}
                inverse
              >
                Cancel
              </Button>
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    )
  }
}

export default DownloadModal

const deleteButton = css`
  background-color: #fe0000;
`
const deleteTextBox = css`
  text-align: center;
`
