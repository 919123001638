import { validateName, validateLongitude, validateLatitude } from '../registration/utils'
import { RegisterSupplierPayload } from '../util/apiUtil'

export function validateSupplierRegistrationPayload(payload: RegisterSupplierPayload) {
  let errorMessage = ''

  const { supplierName, supplierCode, supplierType, location } = payload
  const { longitude, latitude } = location
  const validSupplierName = validateName(supplierName)

  if (!supplierName?.length || !supplierType?.length || !supplierCode.length) {
    errorMessage = 'Required fields cannot be empty'
  } else if (!validSupplierName) {
    errorMessage = 'Invalid Supplier Name'
  } else if (!validateLongitude(longitude)) {
    errorMessage =
      'Invalid Longitude. Value must be between -180 and 180 and have 5 decimals of precision'
  } else if (!validateLatitude(latitude)) {
    errorMessage =
      'Invalid Latitude. Value must be between -90 and 90 and have 5 decimals of precision'
  }

  return errorMessage
}
