import { connect } from 'react-redux'
import { regionSelector } from '../multiregion/reducer'
import { versionSelector } from '../multiversion/reducer'
import { fetchLocations } from '../util/actions'
import { locationsSelector } from '../util/reducer'
import DeviceRegistrationPage from './DeviceRegistrationPage'

const mapStateToProps = state => ({
  locations: locationsSelector(state),
  region: regionSelector(state),
  version: versionSelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(fetchLocations)
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRegistrationPage)
