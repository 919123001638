import React, { SyntheticEvent } from 'react'
import { IconButton } from '@nike/epic-react-ui'
import { propTypes } from 'react-bootstrap/esm/Image'
import { themedButton } from '../commonStyles'

type RegisterButtonProps = {
  handleSubmit: (event: SyntheticEvent) => void
  type: String
}

export const RegisterButton = (props: RegisterButtonProps) => (
  <IconButton
    type='nike-logo'
    onClick={props.handleSubmit}
    aria-label='Register'
    small
    theme={themedButton}
  >
    Register {props.type}
  </IconButton>
)
