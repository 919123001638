/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { ChangeEvent } from 'react'
import { NextGen, Section } from '@nike/epic-react-ui'
import { BasicDeviceFields, BrandName, DeviceType, DropDownObject, deviceTypeDropdownValues } from './types'
import { registrationSectionTitle, inputMargin } from '../commonStyles'
import RestrictedTextInput from '../components/RestrictedTextInput'
import { validateName, nameValidationMessage } from './utils'
import Spacer from '../components/Spacer'
interface BasicDeviceInformationProps extends BasicDeviceFields {
  handleChange?: (event: ChangeEvent<HTMLInputElement>, component: string) => void
  handleSelect?: (selected: DeviceType | string, selectedType: string, component: string) => void
  groupNames: null | string[]
  isReadOnly: boolean
  isUpdate?: boolean
  brand?: null | string
  version?: null | string
}

export const BasicDeviceInformation = (props: BasicDeviceInformationProps) => {
  let groupNameOptions =
    props.groupNames && props.groupNames.length > 1
      ? props.groupNames.map(groupName => ({ value: groupName, label: groupName }))
      : null
  if (!groupNameOptions) {
    groupNameOptions = props.groupNames
      ? props.groupNames.map(locationName => ({ value: locationName, label: locationName }))
      : null
  }
  const handleGroupSelect = (selected: DropDownObject) => {
    return props.handleSelect
      ? props.handleSelect(selected.value, 'groupName', 'BasicDeviceFields')
      : (function () {})()
  }
  return (
    <Section title='General Device Information'>
      <RestrictedTextInput
        required={true}
        onChange={e => (props.handleChange ? props.handleChange(e, 'BasicDeviceFields') : '')}
        name='deviceName'
        label='Device Name'
        value={props.deviceName}
        validation={validateName}
        errorMessage={nameValidationMessage}
        readOnly={(props.brand === BrandName.CONVERSE || props.version === 'v1') ? (props.isUpdate || props.isReadOnly) : true } // Device name cannot change in Greengrass. Please delete the device and recreate it.
      />
      <NextGen.Select
        required
        default='Select Device Type...'
        helperText='Device Type'
        aria-label='Device Type'
        options={deviceTypeDropdownValues}
        value={deviceTypeDropdownValues.find(item => item.value === props.deviceType)}
        onChange={(selected: DropDownObject) => {
          if (selected && props.handleSelect)
            props.handleSelect(selected.value, 'deviceType', 'BasicDeviceFields')
        }}
        isDisabled={props.isReadOnly}
        style={inputMargin}
      />
      <Spacer height={15} />
      {groupNameOptions && !props.isReadOnly ? (
        <NextGen.Select
          required
          default='Select Group Name...'
          helperText='Group Name'
          aria-label='Group Name'
          options={groupNameOptions}
          value={groupNameOptions.find(item => item.value === props.groupName) || ''}
          disabled={props.isReadOnly}
          onChange={handleGroupSelect}
          style={inputMargin}
        />
      ) : (
        <NextGen.TextInput
          required
          disabled
          value={props.groupName}
          onChange={() => {}}
          name='groupName'
          label='Group Name'
          aria-label='Group Name'
          style={inputMargin}
        />
      )}
    </Section>
  )
}
