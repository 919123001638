import { IconButton, NextGen } from '@nike/epic-react-ui'
import React, { ChangeEvent, SyntheticEvent, useState, useEffect, useRef} from 'react'
import RestrictedTextInput from '../components/RestrictedTextInput'
import {
  validateAdGroupName,
  validateName,
  validateIP,
  validatePort,
  nameValidationMessage
} from '../registration/utils'
import { createRegisterV2GroupPayload,registerGroup } from '../util/apiUtil'
import { GroupRegistrationFieldsV2, V2GroupRegistrationResult, 
  GroupType, groupTypeDropdownValues } from './types'
import ErrorMessage from '../components/ErrorMessage'
import { RegisterButton } from '../registration/RegisterButton'
import Spacer from '../components/Spacer'
import { themedButton, inputMargin } from '../commonStyles'


type GroupRegistrationFormProps = {
  onRegistrationComplete: (result: V2GroupRegistrationResult) => void
  version: null | string
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  console.log("reference ",ref.current)
  return ref.current
}

export default function V2GroupRegistrationForm(props: GroupRegistrationFormProps) {
  const [newGroup, setNewGroup] = useState<GroupRegistrationFieldsV2>({
    groupName: '',
    groupType: GroupType.EDGE,
    adGroup: '',
    ip: '',
    port: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  function getErrorMessage() {
    let errorMessage = ''
    const validADGroup = validateAdGroupName(newGroup.adGroup)
    const validGroupName = validateName(newGroup.groupName)
    const validIP = validateIP(newGroup.ip)
    const validPort = validatePort(newGroup.port)

    if (
      !newGroup.groupName.length ||
      !newGroup.adGroup.length ||
      !newGroup.ip.length ||
      !newGroup.port.length
    ) {
      errorMessage = 'Required fields cannot be empty'
    } else if (!validADGroup) {
      errorMessage = 'Invalid AD Group Input'
    } else if (!validGroupName) {
      errorMessage = 'Invalid Group Name'
    } else if (!validIP) {
      errorMessage = 'Invalid IP'
    } else if (!validPort) {
      errorMessage = 'Invalid Port'
    }

    return errorMessage
  }

  console.log ("Version in Form ", props.version)


  function handleV2Select(selected) {
    setNewGroup({
      ...newGroup,
      groupType: selected.value
    })
  }

  function handleV2Change(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    const name = event.target.name 
    setNewGroup({ ...newGroup, [name]: value }) 
  }


  async function handleV2Submit(event: SyntheticEvent) {

    const errorMessage = getErrorMessage()

    if (errorMessage) {
      setErrorMessage(errorMessage)
      return
    }
    const jsonPayload = createRegisterV2GroupPayload(
      newGroup.adGroup,
      newGroup.groupType,
      newGroup.groupName,
      newGroup.ip,
      newGroup.port
    )
    await registerGroup(jsonPayload)

    props.onRegistrationComplete({
      groupName: newGroup.groupName,
      groupType: newGroup.groupType,
      adGroup: newGroup.adGroup,
      ip: newGroup.ip,
      port: newGroup.port
    })
    event.persist()
  }

  return (
    <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
    <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
      <RestrictedTextInput
        required
        onChange={handleV2Change}
        name='groupName'
        label='Group Name'
        value={newGroup.groupName}
        validation={validateName}
        errorMessage={nameValidationMessage}
        helperText={'i.e. NALC'}
      />
      <RestrictedTextInput
        required
        onChange={handleV2Change}
        name='adGroup'
        label='AD Group Name'
        value={newGroup.adGroup}
        validation={validateAdGroupName}
        errorMessage={'Only 0-9, a-z, A-Z, ., _, and - permitted'}
      />
      <NextGen.Select
      required
      default='Select Group Type...'
      helperText='Group Type'
      aria-label='Group Type'
      options={groupTypeDropdownValues}        
      value={groupTypeDropdownValues.find(item => item.value === newGroup.groupType)}
      onChange={handleV2Select}
      style={inputMargin}
    />
    </div>
    <div style={{ display: 'table-cell' }}>
      <RestrictedTextInput
        required
        onChange={handleV2Change}
        name='ip'
        label='IP Address'
        value={newGroup.ip}
        validation={validateIP}
        errorMessage={'Only IP address - permitted'}
        helperText={'i.e. 127.0.0.1'}
      />
      <RestrictedTextInput
        required
        type='number'
        onChange={handleV2Change}
        name='port'
        label='Port Number'
        value={newGroup.port}
        validation={validatePort}
        errorMessage={'Only 0-65535 - permitted'}
        helperText={'i.e. 8080'}
      />
      <RegisterButton type='Group' handleSubmit={handleV2Submit} />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  </div>
  )
}
