import { IconButton, NextGen } from '@nike/epic-react-ui'
import React, { ChangeEvent, SyntheticEvent, useState, useEffect, useRef} from 'react'
import RestrictedTextInput from '../components/RestrictedTextInput'
import {
  validateAdGroupName,
  validateName,
  validateIP,
  validatePort,
  nameValidationMessage
} from '../registration/utils'
import { createRegisterV1GroupPayload, registerGroup } from '../util/apiUtil'
import { GroupRegistrationFieldsV1, V1GroupRegistrationResult } from './types'
import ErrorMessage from '../components/ErrorMessage'
import { RegisterButton } from '../registration/RegisterButton'
import Spacer from '../components/Spacer'
import { themedButton, inputMargin } from '../commonStyles'


type GroupRegistrationFormProps = {
  onRegistrationComplete: (result: V1GroupRegistrationResult) => void
  version: null | string
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  console.log("reference ",ref.current)
  return ref.current
}

export default function V1GroupRegistrationForm(props: GroupRegistrationFormProps) {
  const [newGroup, setNewGroup] = useState<GroupRegistrationFieldsV1>({
    groupName: '',
    adGroup: '',
    ip: '',
    port: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  function getErrorMessage() {
    let errorMessage = ''
    const validADGroup = validateAdGroupName(newGroup.adGroup)
    const validGroupName = validateName(newGroup.groupName)
    const validIP = validateIP(newGroup.ip)
    const validPort = validatePort(newGroup.port)

    if (
      !newGroup.groupName.length ||
      !newGroup.adGroup.length ||
      !newGroup.ip.length ||
      !newGroup.port.length
    ) {
      errorMessage = 'Required fields cannot be empty'
    } else if (!validADGroup) {
      errorMessage = 'Invalid AD Group Input'
    } else if (!validGroupName) {
      errorMessage = 'Invalid Group Name'
    } else if (!validIP) {
      errorMessage = 'Invalid IP'
    } else if (!validPort) {
      errorMessage = 'Invalid Port'
    }

    return errorMessage
  }

  function handleV1Change(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    const name = event.target.name 
    setNewGroup({ ...newGroup, [name]: value }) 
  }

  async function handleV1Submit(event: SyntheticEvent) {

    const errorMessage = getErrorMessage()

    if (errorMessage) {
      setErrorMessage(errorMessage)
      return
    }
    const jsonPayload = createRegisterV1GroupPayload(
      newGroup.adGroup,
      newGroup.groupName,
      newGroup.ip,
      newGroup.port
    )
    await registerGroup(jsonPayload)

    props.onRegistrationComplete({
      groupName: newGroup.groupName,
      adGroup: newGroup.adGroup,
      ip: newGroup.ip,
      port: newGroup.port
    })
    event.persist()
  }

  return (
    <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
    <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
      <RestrictedTextInput
        required
        onChange={handleV1Change}
        name='groupName'
        label='Group Name'
        value={newGroup.groupName}
        validation={validateName}
        errorMessage={nameValidationMessage}
        helperText={'i.e. NALC'}
      />
      <RestrictedTextInput
        required
        onChange={handleV1Change}
        name='adGroup'
        label='AD Group Name'
        value={newGroup.adGroup}
        validation={validateAdGroupName}
        errorMessage={'Only 0-9, a-z, A-Z, ., _, and - permitted'}
      />
    </div>
    <div style={{ display: 'table-cell' }}>
      <RestrictedTextInput
        required
        onChange={handleV1Change}
        name='ip'
        label='IP Address'
        value={newGroup.ip}
        validation={validateIP}
        errorMessage={'Only IP address - permitted'}
        helperText={'i.e. 127.0.0.1'}
      />
      <RestrictedTextInput
        required
        type='number'
        onChange={handleV1Change}
        name='port'
        label='Port Number'
        value={newGroup.port}
        validation={validatePort}
        errorMessage={'Only 0-65535 - permitted'}
        helperText={'i.e. 8080'}
      />
      <RegisterButton type='Group' handleSubmit={handleV1Submit} />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  </div>
  )
}
