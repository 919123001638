/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { NextGen, Section } from '@nike/epic-react-ui'
import { inputMargin, registrationSectionTitle } from '../commonStyles'
import RestrictedTextInput from '../components/RestrictedTextInput'
import {
  validateLatitude,
  validateLongitude,
  latErrorMessage,
  longErrorMessage,
  validateSemver,
  validateIoTAttributeValue,
  iotAttributeValidationMessage
} from './utils'
import { getSuppliers } from '../util/apiUtil'
import { TagDeviceFields, ReadpointType, DropDownObject, brandNameDropdownValues, ControlType } from './types'
import Spacer from '../components/Spacer'

interface TagDeviceInformationProps extends TagDeviceFields {
  handleChange?: (event: ChangeEvent<HTMLInputElement>, component: string) => void
  handleSelect?: (selected: ReadpointType | string, selectedType: string, component: string) => void
  supplierIdOptions: DropDownObject[]
  isReadOnly: boolean
}

const readpointTypeList: DropDownObject[] = [
  { value: ReadpointType.SHIPPING, label: 'SHIPPING' },
  { value: ReadpointType.PRE_SHIPPING, label: 'PRE_SHIPPING' },
  { value: ReadpointType.PACKING_STATION, label: 'PACKING_STATION' }
]

export const TagDeviceInformation = (props: TagDeviceInformationProps) => {
  return (
    <Section title='Device Tags'>
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='userDeviceAlias'
        label='User Device Alias'
        value={props.userDeviceAlias}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='serialNumber'
        label='Serial Number'
        value={props.serialNumber}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='manufacturer'
        label='Manufacturer'
        value={props.manufacturer}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='model'
        label='Model'
        value={props.model}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='firmwareName'
        label='Firmware Name'
        value={props.firmwareName}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='firmwareVersion'
        label='Firmware Version'
        value={props.firmwareVersion}
        validation={(...params) =>
          validateSemver(...params) && validateIoTAttributeValue(...params)
        }
        errorMessage={
          'Must adhear to semver standards i.e. vX.Y.Z where X,Y,Z are non-negative integers'
        }
        readOnly={props.isReadOnly}
      />
      <NextGen.Select
        required
        default='Select Brand Name...'
        helperText='Brand Name'
        aria-label='Brand Name'
        name='brand'
        options={brandNameDropdownValues}
        value={brandNameDropdownValues.find(item => item.value === props.brandName)}
        onChange={(selected: DropDownObject) => {
          if (selected && props.handleSelect)
            props.handleSelect(selected.value, 'brandName', 'TagDeviceFields')
        }}
        isDisabled={props.isReadOnly}
        style={inputMargin}
      />
      <Spacer height={15} />

      <NextGen.Select
        required
        helperText='Readpoint Type'
        options={readpointTypeList}
        value={readpointTypeList.find(item => item.value === props.readpointType)}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        onChange={(selected: DropDownObject) =>
          props.handleSelect
            ? props.handleSelect(selected.value, 'readpointType', 'TagDeviceFields')
            : ''
        }
        isClearable
        isDisabled={props.isReadOnly}
        style={inputMargin}
      />
      <Spacer height={15} />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='readpointValue'
        label='Readpoint Value'
        value={props.readpointValue}
        validation={validateIoTAttributeValue}
        errorMessage={iotAttributeValidationMessage}
        readOnly={props.isReadOnly}
      />
      <NextGen.Select
        required
        helperText='Supplier ID'
        aria-label='Supplier ID'
        options={props.supplierIdOptions}
        value={
          props.supplierIdOptions
            ? props.supplierIdOptions.find(item => item.value === props.supplierId) || ''
            : ''
        }
        onChange={(selected: DropDownObject) =>
          props.handleSelect
            ? props.handleSelect(selected.value, 'supplierId', 'TagDeviceFields')
            : ''
        }
        isDisabled={props.isReadOnly}
        style={inputMargin}
      />
      <Spacer height={15} />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='latitude'
        label='Latitude'
        value={props.latitude}
        validation={validateLatitude}
        errorMessage={latErrorMessage}
        type={'number'}
        readOnly={props.isReadOnly}
      />
      <RestrictedTextInput
        required
        onChange={e => (props.handleChange ? props.handleChange(e, 'TagDeviceFields') : '')}
        name='longitude'
        label='Longitude'
        value={props.longitude}
        validation={validateLongitude}
        errorMessage={longErrorMessage}
        type={'number'}
        readOnly={props.isReadOnly}
      />
    </Section>
  )
}
