/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, SyntheticEvent } from 'react'

import { css, jsx } from '@emotion/react'
import { Modal, Button } from '@nike/epic-react-ui'
import { getDeviceCerts } from '../util/apiUtil'
import { DeviceStatus } from '../registration/types'
import { themedButton } from '../commonStyles'

type DownloadModalProps = {
  deviceName: string
  onClose: () => void
}

type DownloadModalState = {
  presignedUrl: null | string
}

class DownloadModal extends Component<DownloadModalProps, DownloadModalState> {
  constructor(props: DownloadModalProps) {
    super(props)
    this.state = {
      presignedUrl: null
    }
    this.handleDownload = this.handleDownload.bind(this)
  }

  async componentDidMount() {
    if (!this.props.deviceName) {
      return
    }
    await this.getPresignedUrl()
  }

  async componentDidUpdate(prevProps: DownloadModalProps) {
    if (this.props.deviceName && prevProps.deviceName !== this.props.deviceName) {
      await this.getPresignedUrl()
    }
  }

  getPresignedUrl = async () => {
    const { deviceName } = this.props
    const { url } = await getDeviceCerts(deviceName)
    this.setState({ presignedUrl: url })
  }

  async handleDownload() {
    if (!this.state.presignedUrl) {
      return
    }

    window.open(this.state.presignedUrl)
    this.props.onClose()
  }

  render() {
    const { onClose, deviceName } = this.props
    return (
      <div>
        <Modal modalSize='sm' onClose={onClose} closeButton show={!!deviceName}>
          <div aria-label='Download Modal'>
            <Modal.Title>
              <h1>Download Certs</h1>
            </Modal.Title>
            <Modal.Content>
              <div css={deleteTextBox}>Download Certs for {this.props.deviceName}?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                css={deleteButton}
                onClick={this.handleDownload}
                disabled={!this.state.presignedUrl}
                aria-label={'Download Button'}
              >
                Download
              </Button>
              <Button theme={themedButton} onClick={onClose} inverse aria-label={'Close Download'}>
                Cancel
              </Button>
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    )
  }
}

export default DownloadModal

const deleteButton = css`
  background-color: #fe0000;
`
const deleteTextBox = css`
  text-align: center;
`
