/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, useState, useEffect } from 'react'
import { css, jsx } from '@emotion/react'
import { Button } from '@nike/epic-react-ui'
import UpdateIcon from '@material-ui/icons/Update'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import GetAppIcon from '@material-ui/icons/GetApp'
import CustomTable from '../components/CustomTable'
import UpdateModal from './UpdateModal'
import DeleteGroupModal from './DeleteGroupModal'
import DeployGroupModal from './DeployGroupModal'
import UseInterval from '../components/UseInterval'
import DownloadModal from './DownloadModal'
import { Group } from './types'
import { themedButton } from '../commonStyles'
import { GroupStatus } from '../registration/types'

type GroupListProps = {
  groups: Group[]
  version: string,
  fetchGroups: () => Promise<void>
}

export default function GroupList(props: GroupListProps) {
  const [polling, setPolling] = useState(true)
  const [selectedGroupForDelete, setSelectedGroupForDelete] = useState('')
  const [selectedGroupForDeploy, setSelectedGroupForDeploy] = useState('')
  const [selectedGroupForUpdate, setSelectedGroupForUpdate] = useState('')
  const [selectedGroupForDownload, setSelectedGroupForDownload] = useState('')
  useEffect(() => {
    props.fetchGroups()
  }, [])

  function handleModalOpen() {
    setPolling(false)
  }

  function handleModalClose() {
    props.fetchGroups()
    setPolling(true)
    setSelectedGroupForDelete('')
    setSelectedGroupForDeploy('')
    setSelectedGroupForUpdate('')
    setSelectedGroupForDownload('')
  }

  function onSelectGroupForDelete(groupName: string) {
    setSelectedGroupForDelete(groupName)
    setSelectedGroupForDeploy('')
    setSelectedGroupForUpdate('')
    setSelectedGroupForDownload('')
  }

  function onSelectGroupForUpdate(groupName: string) {
    setSelectedGroupForDelete('')
    setSelectedGroupForDeploy('')
    setSelectedGroupForUpdate(groupName)
    setSelectedGroupForDownload('')
  }

  function onSelectGroupForDownload(groupName: string) {
    setSelectedGroupForDelete('')
    setSelectedGroupForDeploy('')
    setSelectedGroupForUpdate('')
    setSelectedGroupForDownload(groupName)
  }

  function onSelectGroupForDeploy(groupName: string) {
    setSelectedGroupForDelete('')
    setSelectedGroupForDeploy(groupName)
    setSelectedGroupForUpdate('')
    setSelectedGroupForDownload('')
  }

  function getSelectedGroup(groupName: string): Group | null {
    const selectedGroup = props.groups.find(group => group.groupName === groupName)
    return selectedGroup || null
  }

  function getColumns (props) {
    const { version } = props
    const columns = props.version == 'v1' ? [
      {
        Header: 'Group Name',
        accessor: 'groupName'
      },
      {
        Header: 'AD Group',
        accessor: 'adGroupId'
      },
      {
        Header: 'Group Status',
        accessor: 'groupStatus'
      },
      {
        Header: 'Deployment Status',
        accessor: 'deploymentStatus'
      },
      {
        Header: 'IP',
        accessor: 'ip'
      },
      {
        Header: 'Port',
        accessor: 'port'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: props => ActionsCell(props, version)
      }
     ] :
    [
      {
        Header: 'Group Name',
        accessor: 'groupName'
      },
      {
        Header: 'Group Type',
        accessor: 'groupType'
      },
      {
        Header: 'AD Group',
        accessor: 'adGroupId'
      },
      {
        Header: 'IP',
        accessor: 'ip'
      },
      {
        Header: 'Port',
        accessor: 'port'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: props => ActionsCell(props, version)
      }
    ]
    return columns
  }

  function ActionsCell(props, version) {
    return (
      <div css={cell}>
        <div css={tableCell}>
          <Button
            aria-label='open download modal'
            small
            disabled={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED}
            theme={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED ? {} : themedButton}
            onClick={() => {
              onSelectGroupForDownload(props.row.original.groupName)
            }}
          >
            <GetAppIcon />
          </Button>
        </div>
        <div css={tableCell}>
          <Button
            aria-label='open update modal'
            disabled={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED}
            theme={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED ? {} : themedButton}
            small
            onClick={() => {
              onSelectGroupForUpdate(props.row.original.groupName)
            }}
          >
            <UpdateIcon />
          </Button>
        </div>
        <div css={tableCell}>
          <Button
            aria-label='open delete modal'
            disabled={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED}
            theme={version == 'v1' && props.row.original.groupStatus !== GroupStatus.COMPLETED ? {} : themedButton}
            small
            onClick={() => {
              onSelectGroupForDelete(props.row.original.groupName)
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
        {version != 'v2' ?
        <div css={tableCell}>
          <Button
            aria-label='open deploy modal'
            small
            disabled={props.row.original.groupStatus !== GroupStatus.COMPLETED }
            theme={props.row.original.groupStatus !== GroupStatus.COMPLETED ? {} : themedButton}
            onClick={() => {
              onSelectGroupForDeploy(props.row.original.groupName)
            }}
          >
            <CloudUploadIcon />
          </Button>
        </div>: null}
      </div>
    )
  }

  return (
    <div css={cell}>
      <DeleteGroupModal
        group={getSelectedGroup(selectedGroupForDelete)}
        onClose={handleModalClose}
      />
      <UpdateModal group={getSelectedGroup(selectedGroupForUpdate)} onClose={handleModalClose} />
      <DownloadModal groupName={selectedGroupForDownload} onClose={handleModalClose} />
      <DeployGroupModal groupName={selectedGroupForDeploy} onClose={handleModalClose} />
      <UseInterval callback={props.fetchGroups} delay={3e3} />

      <div css={table}>
        <CustomTable columns={getColumns(props)} data={props.groups} />
      </div>
    </div>
  )
}

const cell = css`
  display: flex;
  justify-content: center;
`
const table = css`
  margin-top: 20px;
  width: 80%;
`
const tableCell = css`
  display: table-cell;
  padding: 10px;
`
const selectLocationDiv = css`
  width: 100%;
`
