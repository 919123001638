/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react'
import { css, jsx } from '@emotion/react'
import { Button } from '@nike/epic-react-ui'
import UpdateIcon from '@material-ui/icons/Update'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomTable from '../components/CustomTable'
import { Supplier } from '../util/apiUtil'
import { themedButton } from '../commonStyles'

type SuppliersListProps = {
  suppliers: Supplier[]
  setSupplierForDelete: (supplierName: string) => void
  setSupplierForUpdate: (supplierName: string) => void
}

const SuppliersList = (props: SuppliersListProps) => {
  const columns = [
    {
      Header: 'Supplier Name',
      accessor: 'supplierName'
    },
    {
      Header: 'Supplier Type',
      accessor: 'supplierType'
    },
    {
      Header: 'Supplier Code',
      accessor: 'supplierCode'
    },
    {
      Header: 'Latitude',
      accessor: 'location.latitude'
    },
    {
      Header: 'Longitude',
      accessor: 'location.longitude'
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: cellProps =>
        ActionsCell(cellProps, props.setSupplierForDelete, props.setSupplierForUpdate)
    }
  ]
  return (
    <div css={cell}>
      <div css={table}>
        <CustomTable columns={columns} data={props.suppliers} />
      </div>
    </div>
  )
}

export default SuppliersList

type ActionsCellProps = {
  row: {
    [x: string]: any
  }
}

function ActionsCell(
  props: ActionsCellProps,
  setSupplierForDelete: (supplierName: string) => void,
  setSupplierForUpdate: (supplierName: string) => void
) {
  return (
    <div css={cell}>
      <div css={tableCell}>
        <Button
          small
          aria-label='Update'
          theme={themedButton}
          onClick={() => {
            setSupplierForUpdate(props.row.original.supplierName)
          }}
        >
          <UpdateIcon />
        </Button>
      </div>
      <div css={tableCell}>
        <Button
          small
          theme={themedButton}
          aria-label='Delete'
          onClick={() => {
            setSupplierForDelete(props.row.original.supplierName)
          }}
        >
          <DeleteIcon />
        </Button>
      </div>
    </div>
  )
}
const cell = css`
  display: flex;
  justify-content: center;
`
const table = css`
  margin-top: 50px;
  width: 80%;
`
const tableCell = css`
  display: table-cell;
  padding: 10px;
`

const deleteButton = css`
  background-color: #fe0000;
`
