import { connect } from 'react-redux'
import { devicesSelector } from './reducer'
import { isReadOnlySelector } from '../auth/adGroupReducer'
import { suppliersSelector } from '../suppliers/reducer'
import { locationsSelector } from '../util/reducer'
import { regionSelector } from '../multiregion/reducer'
import { versionSelector } from '../multiversion/reducer'
import { fetchLocations } from '../util/actions'
import { fetchAllDevices } from './actions'
import { fetchSuppliers } from '../suppliers/actions'
import DevicesPage from './DevicesPage'

const mapStateToProps = state => ({
  suppliers: suppliersSelector(state),
  devices: devicesSelector(state),
  locations: locationsSelector(state),
  region: regionSelector(state),
  isReadOnly: isReadOnlySelector(state),
  version: versionSelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchSuppliers: () => dispatch(fetchSuppliers),
  fetchAllDevices: (groupNames: string[]) => dispatch(fetchAllDevices(groupNames)),
  fetchLocations: () => dispatch(fetchLocations)
})

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage)
