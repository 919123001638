/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { NextGen, PageSpinner, Button } from '@nike/epic-react-ui'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import UpdateIcon from '@material-ui/icons/Update'
import GetAppIcon from '@material-ui/icons/GetApp'

import { ControlType, DeviceInListDevices, DeviceStatus, DropDownObject } from '../registration/types'
import CustomTable from '../components/CustomTable'
import UpdateModal from './UpdateModal'
import DeleteModal from './DeleteModal'
import DownloadModal from './DownloadModal'
import ConnectivityModal from './ConnectivityModal'
import ViewModal from './ViewModal'
import { getSuppliers, Supplier } from '../util/apiUtil'
import { themedButton } from '../commonStyles'
import UseInterval from '../components/UseInterval'
import PaginationTable from '../components/PaginationTable'
import { Group } from '../groups/types'

interface DevicesPageProps {
  locations: string[]
  suppliers: Supplier[]
  devices: DeviceInListDevices[]
  region: string,
  version: string,
  isReadOnly: boolean
  fetchAllDevices: (selectedGroups: string[]) => Promise<any>
  fetchLocations: () => Promise<any>
  fetchSuppliers: () => Promise<any>
}
export default function DevicesPage(props: DevicesPageProps) {
  const [tableData, setTableData] = useState([] as any)
  const [showPageSpinner, setShowPageSpinner] = useState(true)
  const [listDevicesError, setListDevicesError] = useState(false)
  const [groupName, setGroupName] = useState([] as any)
  const [supplierIdOptions, setSupplierIdOptions] = useState([] as any)
  const [selectedDeviceForDelete, setSelectedDeviceForDelete] = useState('')
  const [selectedDeviceForUpdate, setSelectedDeviceForUpdate] = useState('')
  const [selectedDeviceForDownload, setSelectedDeviceForDownload] = useState('')
  const [selectedDeviceForView, setSelectedDeviceForView] = useState('')
  const [selectedDeviceForConnectivity, setSelectedDeviceForConnectivity] = useState('')
  const [paginationIndex, setPaginationIndex] = useState(0)

  const prevRegion = usePrevious(props.region)
  const prevVersion = usePrevious(props.version)
  let groupNames = props.locations?.length >= 1 ? props.locations : null
  let groupNameOptions =
    props.locations?.length >= 1
      ? props.locations.map(locationName => ({ value: locationName, label: locationName }))
      : null

  if (!groupNameOptions) {
    groupNameOptions = props.locations.map(locationName => ({
      value: locationName,
      label: locationName
    }))
  }

  // Initial fetch on first mount
  useEffect(() => {
    populateSuppliers()
    props.fetchLocations()
    props.fetchSuppliers()
  }, [])

  useEffect(() => {
    setShowPageSpinner(true)
    setGroupName('')
    populateSuppliers()
    props.fetchAllDevices([])
    props.fetchLocations()
  }, [props.region, props.version],)

  useEffect(() => {
    fetchDevices()
  }, [groupName])

  useEffect(() => {
    if (props.suppliers?.length > 0) {
      setSupplierIdOptions(
        props.suppliers.map(supplier => ({
          value: supplier.supplierName,
          label: supplier.supplierName
        }))
      )
    }
  }, [props.suppliers])

  useEffect(() => {
    if (props.devices instanceof Error) {
      setListDevicesError(true)
      setShowPageSpinner(false)
      return
    }
    const devicesWithControlType = enhanceDevicesWithControlType(props.devices)
    setTableData(devicesWithControlType)
    setShowPageSpinner(false)
  }, [props.devices])

  async function populateSuppliers() {
    const resp = await getSuppliers()
    setSupplierIdOptions(
      resp?.suppliers.map(supplier => ({
        value: supplier.supplierName,
        label: supplier.supplierName
      }))
    )
  }

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  function handleSelect(selected: DropDownObject) {
    setShowPageSpinner(true)
    setGroupName(selected ? selected.value : '')

    // List Devices endpoint
    let selectedGroup = selected ? [selected.value] : props.locations
    props.fetchAllDevices(selectedGroup)
  }

  function getSelectedDevice(deviceName: string) {
    return props.devices.find(device => device.deviceName === deviceName)
  }

  function handleModalClose() {
    setSelectedDeviceForConnectivity('')
    setSelectedDeviceForDownload('')
    setSelectedDeviceForView('')
    setSelectedDeviceForUpdate('')
    setSelectedDeviceForDelete('')
  }

  function handleSelectDeviceForConnectivity(deviceName: string) {
    setSelectedDeviceForConnectivity(deviceName)
    setSelectedDeviceForDownload('')
    setSelectedDeviceForView('')
    setSelectedDeviceForUpdate('')
    setSelectedDeviceForDelete('')
  }

  function handleSelectDeviceForDownload(deviceName: string) {
    setSelectedDeviceForConnectivity('')
    setSelectedDeviceForDownload(deviceName)
    setSelectedDeviceForView('')
    setSelectedDeviceForUpdate('')
    setSelectedDeviceForDelete('')
  }

  function handleSelectDeviceForView(deviceName: string) {
    setSelectedDeviceForConnectivity('')
    setSelectedDeviceForDownload('')
    setSelectedDeviceForView(deviceName)
    setSelectedDeviceForUpdate('')
    setSelectedDeviceForDelete('')
  }

  function handleSelectDeviceForUpdate(deviceName: string) {
    setSelectedDeviceForConnectivity('')
    setSelectedDeviceForDownload('')
    setSelectedDeviceForView('')
    setSelectedDeviceForUpdate(deviceName)
    setSelectedDeviceForDelete('')
  }

  function handleSelectDeviceForDelete(deviceName: string) {
    setSelectedDeviceForConnectivity('')
    setSelectedDeviceForDownload('')
    setSelectedDeviceForView('')
    setSelectedDeviceForUpdate('')
    setSelectedDeviceForDelete(deviceName)
  }

  function fetchDevices() {
    const groupNames = groupName ? [groupName] : []
    props.fetchAllDevices(groupNames)

  }

  const actionsCell = props.isReadOnly
    ? []
    : [
        {
          Header: 'Actions',
          accessor: 'actions',
          Cell: props => ActionsCell(props)
        }
      ]

  const columns = props.version == "v1" ? [
    {
      Header: 'Control Type',
      accessor: 'controlType'
    },
    {
      Header: 'User Device Alias',
      accessor: 'userDeviceAlias'
    },
    {
      Header: 'Device Name',
      accessor: 'deviceName'
    },
    {
      Header: 'Device ID',
      accessor: 'deviceId'
    },
    {
      Header: 'Device Status',
      accessor: 'deviceStatus'
    },
    {
      Header: 'Date Created',
      accessor: (tableData: DeviceInListDevices) => tableData.createdAt
    },
    {
      Header: 'Last Updated',
      accessor: (tableData: DeviceInListDevices) => tableData.updatedAt
    },
    ...actionsCell
  ] :
  [
    {
      Header: 'Control Type',
      accessor: 'controlType'
    },
    {
      Header: 'User Device Alias',
      accessor: 'userDeviceAlias'
    },
    {
      Header: 'Device Name',
      accessor: 'deviceName'
    },
    {
      Header: 'Device ID',
      accessor: 'deviceId'
    },
    {
      Header: 'Date Created',
      accessor: (tableData: DeviceInListDevices) => tableData.createdAt
    },
    {
      Header: 'Last Updated',
      accessor: (tableData: DeviceInListDevices) => tableData.updatedAt
    },
    ...actionsCell
  ]


  function ActionsCell(cellProps) {
    return (
      <div css={cell}>
        <div css={tableCell}>
          <Button
            theme={themedButton}
            small
            onClick={() => handleSelectDeviceForConnectivity(cellProps.row.original.deviceName)}
            aria-label='Connectivity'
          >
            <InfoIcon />
          </Button>
        </div>
        <div css={tableCell}>
          <Button
            theme={(props.version == 'v1') &&  (cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS) ? {} : themedButton}
            small
            onClick={() => handleSelectDeviceForDownload(cellProps.row.original.deviceName)}
            disabled={(props.version == 'v1') &&  (cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS)}
            aria-label='Download'
          >
            <GetAppIcon />
          </Button>
        </div>
        <div css={tableCell}>
          <Button
            theme={themedButton}
            small
            onClick={() => handleSelectDeviceForView(cellProps.row.original.deviceName)}
            aria-label='View Info'
          >
            <VisibilityIcon />
          </Button>
        </div>
        <div css={tableCell}>
          <Button
            disabled={cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS}
            theme={cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS ? {} : themedButton}
            small
            onClick={() => handleSelectDeviceForUpdate(cellProps.row.original.deviceName)}
            aria-label='Update'
          >
            <UpdateIcon />
          </Button>
        </div>
        
        <div css={tableCell}>
          <Button
            disabled={(props.version == 'v1') && (cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS) && 
              (cellProps.row.original.deviceStatus !== DeviceStatus.FAILED)}
            theme={(props.version == 'v1') && (cellProps.row.original.deviceStatus !== DeviceStatus.SUCCESS) && 
              (cellProps.row.original.deviceStatus !== DeviceStatus.FAILED) ? {} : themedButton}
            small
            onClick={() => handleSelectDeviceForDelete(cellProps.row.original.deviceName)}
            aria-label='Delete'
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div>
      <ConnectivityModal deviceName={selectedDeviceForConnectivity} onClose={handleModalClose} />
      <ViewModal
        deviceName={selectedDeviceForView}
        groupNames={groupNames}
        supplierIdOptions={supplierIdOptions}
        onClose={handleModalClose}
      />
      <DeleteModal deviceName={selectedDeviceForDelete} onClose={handleModalClose} />
      <UpdateModal
        deviceName={selectedDeviceForUpdate}
        groupNames={groupNames}
        supplierIdOptions={supplierIdOptions}
        onClose={handleModalClose}
      />
      <DownloadModal deviceName={selectedDeviceForDownload} onClose={handleModalClose} />
      {showPageSpinner ? (
        <PageSpinner show={showPageSpinner} />
      ) : listDevicesError ? (
        <div css={cell}>Error fetching devices</div>
      ) : tableData.length > 0 ? (
        <div>
          <div css={selectLocationDiv}>
            <NextGen.Select
              aria-label='Group Select'
              helperText='Group'
              options={groupNameOptions}
              value={groupNameOptions && groupNameOptions.find(item => item.value === groupName)}
              onChange={(selected: DropDownObject) => handleSelect(selected)}
              isClearable
            />
          </div>
          <div css={table}>
            <PaginationTable
              columns={columns}
              data={tableData}
              paginationIndex={paginationIndex}
              onUpdateIndex={index => setPaginationIndex(index)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div css={selectLocationDiv}>
            <NextGen.Select
              aria-label='Group Select'
              helperText='Group'
              options={groupNameOptions}
              value={groupNameOptions && groupNameOptions.find(item => item.value === groupName)}
              onChange={(selected: DropDownObject) => handleSelect(selected)}
              isClearable
            />
          </div>
          <div css={cell}>{/* No devices were returned */}</div>
        </div>
      )}
      <UseInterval callback={fetchDevices} delay={3e3} />
    </div>
  )
}

const enhanceDevicesWithControlType = (devices) => {
  return devices.map(device => ({
    ...device,
    controlType: device.controlType || ControlType.PRIMARY
  }))
}

const cell = css`
  display: flex;
  justify-content: center;
`
const table = css`
  padding-left: 10%;
  width: 80%;
`
const tableCell = css`
  display: table-cell;
  padding: 10px;
`
const selectLocationDiv = css`
  padding-left: 10%;
  width: 30%;
`
