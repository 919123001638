import React from 'react'
import { errorText } from '../commonStyles'
/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

const ErrorMessage = (props: { errorMessage: string }) => {
  const { errorMessage } = props
  return (
    <div css={errorText} aria-label='Error Message'>
      *{errorMessage}
    </div>
  )
}

export default ErrorMessage
