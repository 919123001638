import React from 'react'
import PropTypes from 'prop-types'

const Spacer = (props: { height: number }) => {
  const { height } = props
  return <div style={{ height }} />
}

Spacer.propTypes = {}

export default Spacer
