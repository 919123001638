/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, mediumText, smallMargin, buttonGroupStyle, accentButton } from '../commonStyles'
import { RegistrationResult } from './types'
import { Certs } from '../util/apiUtil'

type RegistrationSuccessProps = {
  resetRegistration: () => void
  deviceName: string
}

const RegistrationSuccess = (props: RegistrationSuccessProps) => {
  return (
    <div>
      <div className='epic-font-brand' css={largeText}>
        Successfully submitted device registration for {props.deviceName}!
      </div>
      <div css={mediumText}>
        When registration is complete, certs will be available to download on the home page.
      </div>
      <div css={buttonGroupStyle}>
        <IconButton type='arrow-backward' positionFirst onClick={() => navigate('/home')}>
          Device List
        </IconButton>
        <Button onClick={props.resetRegistration} css={smallMargin}>
          Register New Device
        </Button>
      </div>
    </div>
  )
}

export default RegistrationSuccess
