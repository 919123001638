/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import SortIcon from '@material-ui/icons/SortByAlpha'
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'

export const NoSortIcon = ({ columnName }) => (
  <SortIcon aria-label={`${columnName} No Sort`} style={sortIconInactive} />
)

export const SortAscendingIcon = ({ columnName }) => (
  <span>
    <SortIcon aria-label={`${columnName} Sort Ascending`} style={sortIconActive} />
    <UpArrowIcon style={sortIconActive} />
  </span>
)

export const SortDescendingIcon = ({ columnName }) => (
  <span>
    <SortIcon aria-label={`${columnName} Sort Descending`} style={sortIconActive} />
    <DownArrowIcon style={sortIconActive} />
  </span>
)

const sortIconActive = {
  fontSize: '1rem'
}

const sortIconInactive = {
  fontSize: '1rem',
  opacity: 0.4
}
