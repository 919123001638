import { AuthEvents, selectLoginError, selectAccessToken } from '@nike/oauth-client'
import { navigate } from 'raviger'
import jwtDecode from 'jwt-decode'

import { notifyError } from '../notifications/actions.js'
import { setGlobalHeader } from '../util/http.js'

export const SET_AD_GROUPS = 'SET_AD_GROUPS'

const events = new AuthEvents()

events.on('loginSuccess', (dispatch, getState) => {
  const state = getState().auth

  if (state.previousLocation) {
    navigate(state.previousLocation.pathname || '/', true)
  }

  const token = selectAccessToken(getState())
  setGlobalHeader('Authorization', 'Bearer ' + token)

  const adGroups = jwtDecode(token)?.groups
  dispatch({
    type: SET_AD_GROUPS,
    payload: adGroups
  })
  console.log(token) // To get token for development, delete later
})

events.on('loginError', (dispatch, getState) => {
  const loginError = selectLoginError(getState())

  if (loginError) {
    notifyError({ message: loginError })
  }
})

events.on('refreshSuccess', (dispatch, getState) => {
  setGlobalHeader('Authorization', 'Bearer ' + selectAccessToken(getState()))
})

events.on('refreshError', (dispatch, getState) => {
  const loginError = selectLoginError(getState())

  if (loginError) {
    notifyError({ message: loginError })
  }
})

events.on('logoutError', () => {
  navigate('/')
})

export default events
