/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { useState, useEffect, useRef } from 'react'
import DeviceRegistrationForm from './DeviceRegistrationForm'
import RegistrationSuccess from './RegistrationSuccess'
import { RegistrationResult } from './types'
import { RegisterDeviceResponse } from '../util/apiUtil'
import RegistrationFailure from './RegistrationFailure'
import { listDevices } from '../util/apiUtil'
import { DeviceInListDevices } from '../registration/types'

function instanceOfRegistrationResponse(object: any): object is RegisterDeviceResponse {
  if (!object) {
    return false
  }
  return 'deviceName' in object
}

function DeviceRegistrationPage(props) {
  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false)
  const [registrationResult, setRegistrationResult] = useState<RegistrationResult | null>(null)
  const [deviceNames, setDeviceNames] = useState<string[] | null>(null)

  const prevRegion = usePrevious(props.region)

  useEffect(() => {
    let isMounted = true;
    
    if (props.locations.size == 0) {
      ;(async function fetchLocationsFn() {
        await props.fetchLocations()
      })()
    }

    if (prevRegion && props.region !== prevRegion) {
      ;(async function fetchLocationsFn() {
        await props.fetchLocations()
      })()
    }

    if (!deviceNames) {
      ;(async function listDevicesFn() {
        let devices: DeviceInListDevices[] = []
        let result = await listDevices({ groupNames: props.locations })
        if (result != null) {
          devices = result.devices
        }
        if (devices && isMounted) {
          setDeviceNames(devices.map(d => d.deviceName))
        }
      })()
    }

    return () => {
      isMounted = false;
    };
  })

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  function handleRegistrationComplete(result: RegistrationResult | null) {
    setRegistrationComplete(true)
    setRegistrationResult(result)
  }

  function handleResetRegistrationPage() {
    setRegistrationComplete(false)
    setRegistrationResult(null)
  }

  function getRegistrationCompletePage() {
    return instanceOfRegistrationResponse(registrationResult) ? (
      <RegistrationSuccess
        deviceName={registrationResult.deviceName}
        resetRegistration={handleResetRegistrationPage}
      />
    ) : (
      <RegistrationFailure
        error={registrationResult}
        resetRegistration={handleResetRegistrationPage}
      />
    )
  }

  return (
    <div>
      {registrationComplete ? (
        getRegistrationCompletePage()
      ) : (
        <DeviceRegistrationForm
          onRegistrationComplete={handleRegistrationComplete}
          groupNames={props.locations}
          deviceNames={deviceNames}
          region={props.region}
          version={props.version}
        />
      )}
    </div>
  )
}

export default DeviceRegistrationPage
