import { createAction } from '@nike/redux-action-utils'
import { listDevices, ListDevicesRequestBody } from '../util/apiUtil'

const SET_DEVICES = 'SET_DEVICES'
export const setDevices = createAction(SET_DEVICES)

export const fetchAllDevices = (groupNames) => async dispatch => {
  let jsonBody: ListDevicesRequestBody = { groupNames }
  const response = await listDevices(jsonBody)
  dispatch({
    type: setDevices.type,
    payload: response.devices
  })
}

