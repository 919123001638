import { combineReducers } from 'redux'
import { parseErrorMiddleware } from '@nike/redux-action-utils'
import { reducer as auth } from '@nike/oauth-client'
import offline from '../offline/reducer'
import notifications from '../notifications/reducer'
import locations from '../util/reducer'
import suppliers from '../suppliers/reducer'
import adGroups from '../auth/adGroupReducer'
import multiregion from '../multiregion/reducer'
import multiversion from '../multiversion/reducer'
import devices from '../devices/reducer'
import groups from '../groups/reducer'

const rootReducer = combineReducers({
  auth,
  parseErrorMiddleware,
  offline,
  notifications,
  locations,
  adGroups,
  suppliers,
  multiregion,
  multiversion,
  devices,
  groups
})

export default rootReducer
