/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'
import { navigate } from 'raviger'
import { Button, IconButton } from '@nike/epic-react-ui'
import { largeText, buttonGroupStyle, accentButton, blueText } from '../commonStyles'
import { smallText } from '../commonStyles'

const RegistrationSuccess = (props: { supplierName: string; resetRegistration: () => void }) => {
  const { supplierName, resetRegistration } = props

  const nowUTC = new Date().toISOString()

  return (
    <div aria-label='Supplier Registration Success'>
      <div className='epic-font-brand' css={largeText}>
        Supplier registration success!
      </div>
      <div css={smallText}>
        Registered supplier: <span css={blueText}>{supplierName}</span> at:{' '}
        <span css={blueText}>{nowUTC}</span>
      </div>
      <div css={buttonGroupStyle}>
        <IconButton type='arrow-backward' positionFirst onClick={resetRegistration}>
          Back to Supplier Registration Page
        </IconButton>
      </div>
    </div>
  )
}

export default RegistrationSuccess
