/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { TableContainer, Thead, Tbody, Tr, Td, Th, Button } from '@nike/epic-react-ui'
import { useEffect } from 'react'
import { themedButton } from '../commonStyles'
const { useTable, usePagination, useSortBy } = require('react-table')
import SortIcon from '@material-ui/icons/SortByAlpha'
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import { NoSortIcon, SortAscendingIcon, SortDescendingIcon } from './SortIcons'
import { ControlType } from '../registration/types'

const isSortable = column => column.Header !== 'Actions'

function PaginationTable({ columns, data, paginationIndex, onUpdateIndex }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: { 
        pageIndex: paginationIndex, 
        pageSize: 15,
        sortBy: [
          {
            id: 'controlType',
            desc: false
          }
        ]
      }
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    gotoPage(paginationIndex)
  }, [paginationIndex])

  return (
    <div>
      <TableContainer {...getTableProps()}>
        <Thead css={header}>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return isSortable(column) ? (
                  <Th
                    aria-label={`${column.render('Header')} Sort`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {' '}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortDescendingIcon columnName={column.render('Header')} />
                        ) : (
                          <SortAscendingIcon columnName={column.render('Header')} />
                        )
                      ) : (
                        <NoSortIcon columnName={column.render('Header')} />
                      )}
                    </span>
                  </Th>
                ) : (
                  <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const style = cell.column.id === 'controlType' ? {
                    backgroundColor: cell.value === ControlType.PRIMARY ? 'rgba(81,214,151,0.8)' : 'rgba(81,151,214,0.8)'
                  } : {}
                  return (<Td style={style} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Td>)
                })}
              </Tr>
            )
          })}
        </Tbody>
      </TableContainer>
      <div style={{ marginTop: 15, textAlign: 'right' }}>
        <span style={{ marginRight: 15 }} aria-label='Page Indicator'>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <Button
          small
          aria-label='First Page Button'
          theme={canPreviousPage ? themedButton : {}}
          onClick={() => onUpdateIndex(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>{' '}
        <Button
          small
          aria-label='Previous Page Button'
          theme={canPreviousPage ? themedButton : {}}
          onClick={() => onUpdateIndex(pageIndex - 1)}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>{' '}
        <Button
          small
          aria-label='Next Page Button'
          theme={canNextPage ? themedButton : {}}
          onClick={() => onUpdateIndex(pageIndex + 1)}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>{' '}
        <Button
          small
          aria-label='Last Page Button'
          theme={canNextPage ? themedButton : {}}
          onClick={() => onUpdateIndex(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
      </div>
    </div>
  )
}
export default PaginationTable

const header = css`
  text-align: center;
`
