import { connect } from 'react-redux'
import { versionSelector } from '../multiversion/reducer'
import GroupRegistrationPage from './GroupRegistrationPage'

const mapStateToProps = state => ({
  version: versionSelector(state)
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GroupRegistrationPage)
