/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'

export const largeText = css`
  font-size: 22px;
  padding: 10px;
  text-align: left;
`

export const mediumText = css`
  font-size: 14px;
  padding: 10px;
  text-align: left;
`

export const blueText = css`
  color: #5197d6;
`

export const errorText = css`
  font-size: 12px;
  margin-left: 12px;
  margin-top: 2px;
  text-align: left;
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
  color: #fe0000;
`

export const smallText = css`
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;
`

export const smallPadding = css`
  padding: 10px;
`

export const smallMargin = css`
  margin-left: 10px;
`

export const buttonGroupStyle = css`
  margin-top: 20px;
  margin-left: 10px;
`

export const disabled = css`
  opacity: 0.5;
`

export const accentButton = css`
  background-color: #fa5400;
  margin-left: 10px;
`
export const registrationSectionTitle = css`
  font-size: 22px;
  padding: 10px;
  text-align: center;
`
export const deviceDetailsModalOuter = css`
  max-height: 60vh;
  overflow: hidden;
`
export const deviceDetailsModalInner = css`
  overflow-y: scroll;
  height: 30vh;
`

export const themedButton = {
  primary: '#5197D6',
  secondary: 'white'
}

export const inputMargin = {
  marginBottom: 15
}
