/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { Modal, Button, Section } from '@nike/epic-react-ui'
import { BasicDeviceInformation } from '../registration/BasicDeviceFields'
import { TagDeviceInformation } from '../registration/TagDeviceFields'
import ControlTypeInformation from '../registration/ControlTypeInformation'
import {
  DeviceType,
  BasicDeviceFields,
  TagDeviceFields,
  DeviceTopicFields,
  SubscriptionType,
  ReadpointType,
  DropDownObject,
} from '../registration/types'
import { getDevice, GetDeviceResponseBody } from '../util/apiUtil'
import { deviceDetailsModalInner, deviceDetailsModalOuter, themedButton } from '../commonStyles'
import { BrandName, ControlType, DeviceInListDevices, FacilityType, JSONPayload } from '../registration/types'
interface DeviceFields extends BasicDeviceFields, TagDeviceFields, DeviceTopicFields {
  [x: string]: any
}

type ViewModalProps = {
  deviceName: string
  groupNames: string[] | null
  supplierIdOptions: DropDownObject[]
  onClose: () => void
}

export default function ViewModal(props: ViewModalProps) {
  const [deviceData, setDeviceData] = useState<GetDeviceResponseBody | undefined>(undefined)
  useEffect(() => {
    if (!props.deviceName) {
      return
    }
    getDeviceDetails()
  }, [props.deviceName])

  async function getDeviceDetails() {
    try {
      const res = await getDevice(props.deviceName)
      setDeviceData(res)
    } catch (e) {
      console.log(e)
    }
  }

  const { onClose, deviceName, groupNames } = props
  return (
    <div>
      <Modal modalSize='lg' onClose={onClose} closeButton show={!!deviceName}>
        <Modal.Title>
          <h1>View Device</h1>
        </Modal.Title>
        <Modal.Content>
          <div css={deviceDetailsModalOuter}>
            <div css={deviceDetailsModalInner} aria-label='View Modal'>
              <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
                <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
                  <Section>
                    <BasicDeviceInformation
                      deviceType={
                        deviceData && deviceData.deviceType
                          ? DeviceType[deviceData?.deviceType.toUpperCase()]
                          : ''
                      }
                      deviceName={deviceData ? deviceData.deviceName : (deviceName?deviceName:'')}
                      groupName={deviceData ? deviceData.groupName : (Array.isArray(groupNames) && groupNames.length>0?groupNames[0]:'')}
                      groupNames={props.groupNames}
                      isReadOnly={true}
                    />
                    <ControlTypeInformation
                      controlType={deviceData ? deviceData.controlType: ControlType.PRIMARY}
                      facilityType={deviceData ? deviceData.facilityType: FacilityType.DC}
                      subscriptionType={deviceData ? deviceData.subscriptionType: SubscriptionType.VM_TO_LOCAL_DEVICE}
                      handleControlTypeToggle={()=> null }
                      handleFacilityTypeToggle={()=> null}
                      handleSubscriptionTypeToggle={()=> null}
                      isReadOnly={true}
                    />
                  </Section>
                </div>
                <div style={{ display: 'table-cell' }}>
                  <Section>
                    <TagDeviceInformation
                      brandName={deviceData ? deviceData.brand : ''}
                      serialNumber={deviceData ? deviceData.serialNumber : ''}
                      manufacturer={deviceData ? deviceData.manufacturer : ''}
                      model={deviceData ? deviceData.model : ''}
                      firmwareName={deviceData ? deviceData.firmwareName : ''}
                      firmwareVersion={deviceData ? deviceData.firmwareVersion : ''}
                      readpointType={
                        deviceData?.readpointType
                          ? ReadpointType[deviceData.readpointType]
                          : ReadpointType.SHIPPING
                      }
                      readpointValue={deviceData ? deviceData.readpointValue : ''}
                      supplierId={deviceData ? deviceData.supplierId : ''}
                      latitude={deviceData ? deviceData.locationLatitude : ''}
                      longitude={deviceData ? deviceData.locationLongitude : ''}
                      isReadOnly={true}
                      supplierIdOptions={props.supplierIdOptions}
                      subscriptionType={
                        deviceData ? deviceData.subscriptionType : SubscriptionType.DEVICE_TO_EDGEVM
                      }
                      userDeviceAlias={deviceData ? deviceData.userDeviceAlias : ''}
                    />
                  </Section>
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button inverse theme={themedButton} onClick={onClose} aria-label='Close View'>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}
