import React, { SyntheticEvent } from 'react'
import { IconButton } from '@nike/epic-react-ui'
import { propTypes } from 'react-bootstrap/esm/Image'
import { themedButton } from '../commonStyles'

type DeployGGGroupButtonProps = {
  handleSubmit: (event: SyntheticEvent) => void
  type: String
}

export const DeployGGGroupButton = (props: DeployGGGroupButtonProps) => (
  <IconButton
    type='nike-logo'
    onClick={props.handleSubmit}
    aria-label='Deploy'
    small
    theme={themedButton}
  >
    Deploy {props.type}
  </IconButton>
)
