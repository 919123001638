import React from 'react'
import { ToggleSwitch, Section } from '@nike/epic-react-ui'
import { ControlType, FacilityType, SubscriptionType} from './types'
import Spacer from '../components/Spacer'

interface ControlTypeInformationProps {
    controlType: ControlType | undefined
    facilityType: FacilityType | undefined
    subscriptionType: SubscriptionType | undefined
    handleControlTypeToggle?: (selected: ControlType) => void
    handleFacilityTypeToggle?: (selected: FacilityType) => void
    handleSubscriptionTypeToggle?: (selected: SubscriptionType) => void
    isReadOnly?:boolean
}

const controlTypeOptions = [
    { value: ControlType.PRIMARY, label: 'Primary' },
    { value: ControlType.SECONDARY, label: 'Secondary' }
]

const facilityTypeOptions = [
    { value: FacilityType.DC, label: 'DC' },
    { value: FacilityType.FACTORY, label: 'Factory' }
]

const subscriptionType = [
    { value: SubscriptionType.DEVICE_TO_EDGEVM, label: 'DeviceToEdgeVM' },
    { value: SubscriptionType.VM_TO_LOCAL_DEVICE, label: 'VMToLocalDevice' },
    { value: SubscriptionType.CLOUD_TO_IOT, label: 'Cloud' }
]

const ControlTypeInformation = (props: ControlTypeInformationProps) => {
    return (
        <Section title='Control Type'>
            <ToggleSwitch
                label='Facility Type'
                options={facilityTypeOptions}
                value={props.facilityType}
                onChange={props.handleFacilityTypeToggle ? props.handleFacilityTypeToggle : ''}
                aria-label='Facility Type Toggle'
                isDisabled={true}
            />
            <Spacer height={20}/>
            <ToggleSwitch
                label='Control Type'
                options={controlTypeOptions}
                value={props.controlType}
                onChange={props.handleControlTypeToggle ? props.handleControlTypeToggle : ''}
                aria-label='Control Type Toggle'
                isDisabled={false}
            />
            <Spacer height={20}/>
            <ToggleSwitch
                label='Subscription Type'
                options={subscriptionType}
                value={props.subscriptionType}
                onChange={props.handleSubscriptionTypeToggle ? props.handleSubscriptionTypeToggle : ''}
                aria-label='Subscription Type Toggle'
                isDisabled={props.isReadOnly}
            />
        </Section>
    )
}

export default ControlTypeInformation
