export enum SupplierType {
  DC = 'DC',
  '3PL' = '3PL',
  FACTORY = 'Factory'
}

export const supplierTypeDropdownValues: {
  value: SupplierType
  label: string
}[] = [
  { value: SupplierType.DC, label: 'DC' },
  { value: SupplierType.FACTORY, label: 'Factory' },
  { value: SupplierType['3PL'], label: '3PL' }
]
