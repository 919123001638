/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { useRoutes, Link, useRedirect } from 'raviger'
import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  loginUser,
  logoutUser
} from '@nike/oauth-client'
import { Button, NavBar } from '@nike/epic-react-ui'
import RegionSelect from '../components/RegionSelect'
import VersionSelect from '../components/VersionSelect'

import config from '../config'
import routes from './routes'
import NotFoundPage from './NotFoundPage'
import OfflineBanner from '../offline/OfflineBanner'
import { LoginRequired } from '../auth'
import Spinner from '../components/Spinner'
import Error from '../components/Error'
import { adGroupsSelector } from '../auth/adGroupReducer'
import { storeRegion} from '../multiregion/actions'
import { storeVersion } from '../multiversion/actions'
import { groupNavBarLinks } from './constants'
import { themedButton } from '../commonStyles'

const App = ({
  adGroups,
  initializeUserAuth,
  loginError,
  loginFinished,
  loginUser,
  logoutUser
}) => {
  useEffect(() => {
    initializeUserAuth()
  }, [])

  const dispatch = useDispatch()
  const routeResult = useRoutes(routes) || <NotFoundPage />

  const initialRegion = config.regions.find(
    ({ value }) =>
      value ===
      (sessionStorage.getItem('selectedRegion') ||
        localStorage.getItem('lastSelectedRegion') ||
        config.defaultRegion)
  )

  const initialVersion = config.versions.find(
    ({ value }) =>
      value ===
      (sessionStorage.getItem('selectedVersion') ||
        localStorage.getItem('lastSelectedVersion') ||
        config.defaultVersion)
  )

  const [region, setRegion] = useState(initialRegion)
  const [version, setVersion] = useState(initialVersion)

  const handleRegionChange = (newRegion: string) => {
    sessionStorage.setItem('selectedRegion', newRegion) // This storage will survive refreshes but allow a user to have different regions selected in multiple tabs
    localStorage.setItem('lastSelectedRegion', newRegion) // This storage survives browser restarts
    setRegion(config.regions.find(({ value }) => value === newRegion))
    dispatch(storeRegion(newRegion))
  }

  const handleVersionChange = (newVersion: string) => {
    sessionStorage.setItem('selectedVersion', newVersion) // This storage will survive refreshes but allow a user to have different regions selected in multiple tabs
    localStorage.setItem('lastSelectedVersion', newVersion) // This storage survives browser restarts
    setVersion(config.versions.find(({ value }) => value === newVersion))
    dispatch(storeVersion(newVersion))
  }


  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  if (!loginFinished) {
    return <Spinner large />
  }

  const logoutButton = (
    <Button theme={themedButton} inverse small onClick={logoutUser}>
      Logout
    </Button>
  )

  let links = groupNavBarLinks['DEFAULT'].links

  for (let i = 0; i < adGroups.length; i++) {
    if (groupNavBarLinks[adGroups[i]]) {
      links = links.concat(groupNavBarLinks[adGroups[i]].links)
    }
  }

  const userLinks = [...new Set(links.map(s => JSON.stringify(s)))]
  const userLinksJson = userLinks.map(s => JSON.parse(s))

  return (
    <div>
      <OfflineBanner />
      <div>
        <NavBar
          name={config.appFullName}
          logo='/logo.png'
          rootPath='/home'
          routes={userLinksJson}
          RouterLink={Link}
          aside={
            <div css={navAside}>
              {logoutButton}
              <RegionSelect
                region={region}
                options={config.regions}
                onChange={({ value }) => handleRegionChange(value)}
              />
              <VersionSelect
                version={version}
                options={config.versions}
                onChange={({ value }) => handleVersionChange(value)}
              />
            </div>
          }
        ></NavBar>
        <LoginRequired>
          <div style={{ margin: '1rem' }}>{routeResult}</div>
        </LoginRequired>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state),
    adGroups: adGroupsSelector(state)
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App)

const navAside = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
