/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component, ChangeEvent, SyntheticEvent } from 'react'
import { css, jsx } from '@emotion/react'
import isEqual from 'lodash.isequal'
import { Modal, Button, Section, Spinner } from '@nike/epic-react-ui'
import { BasicDeviceInformation } from '../registration/BasicDeviceFields'
import { TagDeviceInformation } from '../registration/TagDeviceFields'
import {
  DeviceType,
  BasicDeviceFields,
  TagDeviceFields,
  ReadpointType,
  DeviceTopicFields,
  SubscriptionType,
  DropDownObject
} from '../registration/types'
import {
  createJsonFromState,
  updateDevice,
  getDevice,
  getGroupSubscriptions
} from '../util/apiUtil'

import { deviceDetailsModalInner, deviceDetailsModalOuter, themedButton } from '../commonStyles'
import { validateJSONPayload } from '../registration/utils'
import ErrorMessage from '../components/ErrorMessage'

type UpdateModalProps = {
  deviceName: string
  groupNames: null | string[]
  supplierIdOptions: DropDownObject[]
  onClose: () => void
}

interface UpdateModalState extends BasicDeviceFields, TagDeviceFields, DeviceTopicFields {
  isLoading: boolean,
  [x: string]: any
}

class UpdateModal extends Component<UpdateModalProps, UpdateModalState> {
  constructor(props: UpdateModalProps) {
    super(props)
    this.state = {
      isLoading: false,
      deviceName: '',
      brandName: '',
      groupName: '',
      deviceType: null,
      serialNumber: '',
      manufacturer: '',
      model: '',
      firmwareName: '',
      firmwareVersion: '',
      readpointType: ReadpointType.PRE_SHIPPING,
      readpointValue: '',
      supplierId: '',
      longitude: '',
      latitude: '',
      subscriptionType: SubscriptionType.CLOUD_TO_IOT,
      localDeviceTopics: {
        subscribedTopics: [],
        allGroupTopics: []
      },
      cloudDeviceTopics: {
        publisherAssociation: '',
        publisherMetric: '',
        subscriberAssociation: '',
        subscriberMetric: ''
      },
      errorMessage: '',
      userDeviceAlias: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
  }

  async componentDidMount() {
    if (!this.props.deviceName) {
      return
    }
    await this.setDeviceFields()
  }

  async componentDidUpdate(prevProps: UpdateModalProps) {
    if (prevProps.deviceName === this.props.deviceName || !this.props.deviceName) {
      return
    }
    await this.setDeviceFields()
  }

  setDeviceFields = async () => {
    const res = await getDevice(this.props.deviceName)
    const deviceType: DeviceType = res.deviceType ? DeviceType[res.deviceType.toUpperCase()] : ''
    this.setState({
      deviceName: res.deviceName,
      deviceType: deviceType,
      serialNumber: res.serialNumber,
      manufacturer: res.manufacturer,
      model: res.model,
      brandName: res.brand,
      firmwareName: res.firmwareName,
      firmwareVersion: res.firmwareVersion,
      readpointType: res.readpointType,
      readpointValue: res.readpointValue,
      supplierId: res.supplierId,
      longitude: res.locationLongitude,
      latitude: res.locationLatitude,
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
      groupName: res.groupName,
      subscriptionType: res.subscriptionType
    })
    if (this.state.subscriptionType == SubscriptionType.CLOUD_TO_IOT) {
      this.setState({
        cloudDeviceTopics: {
          publisherAssociation: res.publisherAssociationTopic || '',
          publisherMetric: res.publisherMetricTopic || '',
          subscriberAssociation: res.subscriberAssociationTopic || '',
          subscriberMetric: res.subscriberMetricTopic || ''
        }
      })
    }
    if (this.state.subscriptionType == SubscriptionType.VM_TO_LOCAL_DEVICE && res.groupName) {
      const groupAssociationTopics = await getGroupSubscriptions(res.groupName)
      this.setState({
        localDeviceTopics: {
          allGroupTopics: groupAssociationTopics,
          subscribedTopics: res.localDeviceSubscriptions || []
        }
      })
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  handleCheck = (event: ChangeEvent<HTMLInputElement>, topic: string) => {
    if (!this.state.localDeviceTopics) {
      return
    }

    const localTopics = this.state.localDeviceTopics.subscribedTopics
    if (localTopics?.some(s => s === topic)) {
      this.setState({
        localDeviceTopics: {
          ...this.state.localDeviceTopics,
          subscribedTopics: localTopics.filter(obj => obj !== topic)
        }
      })
    } else {
      if (localTopics)
        this.setState({
          localDeviceTopics: {
            ...this.state.localDeviceTopics,
            subscribedTopics: [...localTopics, topic]
          }
        })
    }
  }

  handleSelect = (selected: DeviceType | ReadpointType | string, selectedType: string) => {
    this.setState({ [selectedType]: selected })
  }

  handleToggle(selected: SubscriptionType) {
    this.setState({ subscriptionType: selected })
  }

  async handleSubmit(event: SyntheticEvent) {
    this.setState({ isLoading: true })
    const jsonPayload = createJsonFromState(this.state, true)

    let errorMessageResp = validateJSONPayload(jsonPayload)
    if (errorMessageResp) {
      this.setState({
        errorMessage: errorMessageResp
      })
      return
    }
    
    let response: any = await updateDevice(jsonPayload)

    if (response?.status === 409) {
      this.setState({ errorMessage: 'Device is in an invalid state. Try again in a few minutes.' })
      return
    }
    if (response?.status !== 200 && response?.status !== 201) {
      this.setState({ isLoading: false })
    }    

    this.props.onClose()
  }

  render() {
    const { onClose, deviceName } = this.props
    return (
      <div>
        <Modal modalSize='lg' onClose={onClose} closeButton show={!!deviceName}>
          <Modal.Title>
            <h1>Update</h1>
          </Modal.Title>
          <Modal.Content>
            <div css={deviceDetailsModalOuter} aria-label='Update Modal'>
              <div css={deviceDetailsModalInner}>
                <div style={{ display: 'table', width: '100%', paddingTop: '15px' }}>
                  <div style={{ display: 'table-cell', paddingRight: '50px', width: '50%' }}>
                    <Section>
                      <BasicDeviceInformation
                        handleChange={this.handleChange}
                        handleSelect={this.handleSelect}
                        deviceType={this.state.deviceType}
                        deviceName={deviceName}
                        groupName={this.state.groupName}
                        groupNames={this.props.groupNames}
                        isReadOnly={false}
                        isUpdate={true}
                      />
                    </Section>
                  </div>
                  <div style={{ display: 'table-cell' }}>
                    <Section>
                      <TagDeviceInformation
                        handleChange={this.handleChange}
                        handleSelect={this.handleSelect}
                        serialNumber={this.state.serialNumber}
                        brandName={this.state.brandName}
                        manufacturer={this.state.manufacturer}
                        model={this.state.model}
                        firmwareName={this.state.firmwareName}
                        firmwareVersion={this.state.firmwareVersion}
                        readpointType={this.state.readpointType}
                        readpointValue={this.state.readpointValue}
                        supplierId={this.state.supplierId}
                        supplierIdOptions={this.props.supplierIdOptions}
                        latitude={this.state.latitude}
                        longitude={this.state.longitude}
                        isReadOnly={false}
                        subscriptionType={this.state.subscriptionType}
                        userDeviceAlias={this.state.userDeviceAlias}
                      />
                    </Section>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button theme={this.state.isLoading ? '' : themedButton} disabled={this.state.isLoading} onClick={this.handleSubmit} aria-label={'Update Button'}>
            { this.state.isLoading ? <Spinner /> : 'Update Device' }
            </Button>
            <Button theme={themedButton} inverse onClick={onClose} aria-label={'Close Update'}>
              Cancel
            </Button>
            {this.state.errorMessage && <ErrorMessage errorMessage={this.state.errorMessage} />}
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default UpdateModal
